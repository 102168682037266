export const FETCH_LIBRARY   = 'FETCH_LIBRARY';
export const FETCH_LIBRARY_DRAFTS   = 'FETCH_LIBRARY_DRAFTS';
export const HANDLE_LIBRARY_LOADING   = 'HANDLE_LIBRARY_LOADING';
export const FETCH_LIBRARY_FILE   = 'FETCH_LIBRARY_FILE';
export const FETCH_LIBRARY_FILE_DATA   = 'FETCH_LIBRARY_FILE_DATA';
export const FETCH_LIBRARY_FOLDER   = 'FETCH_LIBRARY_FOLDER';
export const FETCH_LIBRARY_DRIVE   = 'FETCH_LIBRARY_DRIVE';
export const CLEAR_LIBRARY_FOLDER   = 'CLEAR_LIBRARY_FOLDER';
export const NEW_LIBRARY_FOLDER   = 'NEW_LIBRARY_FOLDER';
export const NEW_LIBRARY_DRIVE   = 'NEW_LIBRARY_DRIVE';
export const NEW_LIBRARY_FILE   = 'NEW_LIBRARY_FILE';
export const DELETE_LIBRARY_FILE   = 'DELETE_LIBRARY_FILE';
export const DELETE_LIBRARY_FOLDER   = 'DELETE_LIBRARY_FOLDER';
export const DELETE_LIBRARY_DRIVE   = 'DELETE_LIBRARY_DRIVE';