import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, sv } from 'date-fns/locale';
import { formatCodeDates } from '../../../helpers/functions/functions';
const localeMap = {
	'en-US': enUS,
	'sv-SE': sv,
};

function RenderDatePicker({ input: { onChange, value }, meta: { invalid, touched, error, active }, ...custom }) {
	if (typeof value === 'string') {
		value = value.split(' ')[0];
	}

	return (
		<MuiPickersUtilsProvider
			utils={DateFnsUtils}
			locale={Object.keys(localeMap).includes(custom.locale) ? localeMap[custom.locale] : localeMap['en-US']}
		>
			<DatePicker
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				format={formatCodeDates(custom.locale, false)}
				label={custom.inputdata.label}
				fullWidth
				inputVariant="outlined"
				onChange={(date) => {
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(date);
					}
					onChange(date);
				}}
				value={value === '' ? null : value}
				disableFuture={custom.inputdata.disableFuture}
				disablePast={custom.inputdata.disablePast}
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				openTo={custom.inputdata.openTo}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default RenderDatePicker;
