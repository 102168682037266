import React, { Component } from 'react';
import eventsService from '../../helpers/Events';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon, Close as CloseIcon, Warning as WarningIcon } from '@material-ui/icons';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
	const { className, message, context, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	var cssClass = '';

	switch (variant) {
		case 'success':
			cssClass = 'success-alteri-color';
			break;
		case 'info':
			cssClass = 'info-alteri-color';
			break;
		case 'warning':
			cssClass = 'warning-alteri-color';
			break;
		case 'error':
			cssClass = 'danger-alteri-color';
			break;
		default:
			break;
	}

	return (
		<SnackbarContent
			className={clsx(cssClass, className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className="generic-messagetoast-message">
					<div>
						<Icon className="generic-messagetoast-icon generic-messagetoast-icon-variant" />
						{message}
					</div>
					{context.length > 0 && (
						<ul>
							{context.map((item) => (
								<li>{item.message}</li>
							))}
						</ul>
					)}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<CloseIcon className="generic-messagetoast-icon" />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string.isRequired,
	context: PropTypes.array,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

class MessageToast extends Component {
	state = {
		open: false,
		type: 'success',
		message: '',
		context: [],
	};

	componentDidMount() {
		eventsService.listenEvent('alert', this.handleClick.bind(this));
	}

	componentWillUnmount() {
		eventsService.unlistenEvent('alert');
	}

	handleClick = (params) => {
		this.setState({
			open: true,
			type: params.type,
			message: params.message,
			context: params.context ? params.context : [],
		});
	};

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			open: false,
		});
	};

	render() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={this.state.open}
				autoHideDuration={3000}
				onClose={this.handleClose}
			>
				<MySnackbarContentWrapper
					onClose={this.handleClose}
					variant={this.state.type}
					message={this.state.message}
					context={this.state.context}
				/>
			</Snackbar>
		);
	}
}

export default MessageToast;
