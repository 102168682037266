/**Task status */
const INCOMPLETE = 0;
const COMPLETED = 100;
const DENIED = 50;

/**Task types */
const REJECTION = 1;
const CREATION = 2;
const VALIDATION = 3;
const CONCLUSION = 4;

/**Task type labels */
const REJECTION_LABEL = 'rejection';
const CREATION_LABEL = 'creation';
const VALIDATION_LABEL = 'validation';
const CONCLUSION_LABEL = 'conclusion';

/**Task references */
const PRESCRIPTION = 'App\\Models\\Tickets\\Prescriptions';
const TICKET = 'App\\Models\\Tickets';

export default {
	STATUS: {
		INCOMPLETE,
		COMPLETED,
		DENIED,
	},
	TYPE: {
		REJECTION,
		CREATION,
		VALIDATION,
		CONCLUSION,
	},
	TYPE_LABELS: (type) => {
		switch (parseInt(type)) {
			case REJECTION:
				return REJECTION_LABEL;
			case CREATION:
				return CREATION_LABEL;
			case VALIDATION:
				return VALIDATION_LABEL;
			case CONCLUSION:
				return CONCLUSION_LABEL;

			default:
				break;
		}
	},
	REFERENCE: {
		TICKET,
		PRESCRIPTION,
	},
};
