import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavBar from './NavBar';
import Presentation from './Presentation';
import { handleSelectClientAction } from '../Login/actions/loginActions';

class Welcome extends Component {
	componentDidMount() {
		if(!this.props.login) {
			this.props.handleSelectClientAction(null, null);
		}
	}

	render() {
		return (
			<div className="welcome-main">
				<NavBar />
				<div className={'welcome-presentation-content'}>
					<Presentation />
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	settings: state.settings,
	login: state.login.login
});

export default connect(mapStateToProps, { handleSelectClientAction })(withRouter(Welcome));
