import {
	FETCH_CONDITIONS_DATA,
	SELECT_CATEGORY,
	FETCH_CONDITIONS_NAME_DATA
} from '../actions/types';

const initialState = {
	conditions: '',
	selected: '',
	list: '',
	filter: '',
	condition_names: [],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_CONDITIONS_DATA:
			return {
				...state,
				conditions: action.payload.data,
				filter: action.payload.filter
			};
		case SELECT_CATEGORY:
			return {
				...state,
				selected: action.payload
			};
		case FETCH_CONDITIONS_NAME_DATA:
			return {
				...state,
				condition_names: action.payload
			};
		default:
			return state;
	}
}
