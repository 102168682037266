import { PROMPT_DIALOG_IS_DIRTY } from '../actions/types';

const initialState = {
    is_dirty: false,
    path_allowed: null,
    path_not_allowed: null,
    back_label: '',
    next_label: '',
    prompt_dialog_title: '',
    prompt_dialog_description: '',
    allow_resume: true,
    leave_action: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PROMPT_DIALOG_IS_DIRTY:
            return {
                ...state,
                is_dirty: action.payload.is_dirty,
                path_allowed: action.payload.path_allowed,
                path_not_allowed: action.payload.path_not_allowed,
                back_label: action.payload.back_label,
                next_label: action.payload.next_label,
                prompt_dialog_title: action.payload.prompt_dialog_title,
                prompt_dialog_description: action.payload.prompt_dialog_description,
                allow_resume: action.payload.allow_resume,
                leave_action: action.payload.leave_action
            }
        default:
            return state;
    }
}
