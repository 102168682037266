/** eventsService */
module.exports = {
	callbacks: {},

	/**
	 * @param {string} eventName
	 * @param {*} data
	 */
	triggerEvent(eventName, data = null) {
		if (this.callbacks[eventName]) {
			this.callbacks[eventName](data);
		}
	},

	/**
	 * @param {string} eventName name of event
	 * @param {string} id callback identifier
	 * @param {Function} callback
	 */
	listenEvent(eventName, callback) {
		this.callbacks[eventName] = callback;
	},

	/**
	 * @param {string} eventName name of event
	 * @param {string} id callback identifier
	 */
	unlistenEvent(eventName) {
		delete this.callbacks[eventName];
	}
};
