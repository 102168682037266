import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Typography } from '@material-ui/core';

class BlockUI extends Component {
	render() {
		if (!this.props.blocking_ui) {
			return '';
		} else {
			return (
				<div className="block-ui-container">
					<div className="block-ui-overlay" />
					<div className="block-ui-message-container">
						<div className="block-ui-message">
							<Typography variant="body" className="block-ui-typography">
								{this.props.translate('block_ui_loading')}
							</Typography>
							<div className="loading-indicator">
								<svg id="indicator" viewBox="0 0 100 100">
									<circle id="circle" cx="50" cy="50" r="45" />
								</svg>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	blocking_ui: state.generic.blocking_ui,
});

export default connect(mapStateToProps, null)(BlockUI);
