import {
	FETCH_QUESTIONNAIRES,
	FETCH_QUESTIONNAIRE,
	PATCH_QUESTIONNAIRE,
	TRIGGER_QUESTION,
	ANSWER_QUESTION,
	PROGRESS_QUESTIONNAIRE,
	TRIGGER_CHILDREN,
	FETCH_TICKET_QUESTIONNAIRE,
	HANDLE_QUESTIONNAIRE_ERROR,
} from '../actions/types';

const initialState = {
	questionnaires: '',
	completed: false,
	questionnaire: '',
	question_index: 0,
	progress: 0,
	total_points: 0,
	next: true,
	previous: false,
	questionnaire_has_errors: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_QUESTIONNAIRES:
			return {
				...state,
				questionnaires: action.payload,
			};
		case FETCH_QUESTIONNAIRE:
			return {
				...initialState,
				questionnaire: action.payload.questionnaire,
			};
		case TRIGGER_QUESTION:
			return {
				...state,
				question_index: action.payload.question_index,
				next: action.payload.next,
				previous: action.payload.previous,
			};
		case ANSWER_QUESTION:
			return {
				...state,
				questionnaire: action.payload,
			};

		case TRIGGER_CHILDREN:
			return {
				...state,
				questionnaire: action.payload,
			};
		case PROGRESS_QUESTIONNAIRE:
			return {
				...state,
				progress: action.payload,
			};
		case PATCH_QUESTIONNAIRE:
			return {
				...state,
				completed: action.payload.completed,
			};
		case FETCH_TICKET_QUESTIONNAIRE:
			return {
				...initialState,
				questionnaire: action.payload.questionnaire,
				completed: action.payload.completed,
				questionnaires: state.questionnaires
			};
		case HANDLE_QUESTIONNAIRE_ERROR:
			return {
				...state,
				questionnaire: action.payload.questionnaire,
				questionnaire_has_errors: action.payload.questionnaire_has_errors,
			};
		default:
			return state;
	}
}
