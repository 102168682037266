// Eval actions and modifies store

const initialState = {
    protocol: process.env.NODE_ENV === 'development' ? 'http' : process.env.REACT_APP_API_PROTOCOL,
    apihost: process.env.NODE_ENV === 'development' ? 'http://localhost' + process.env.REACT_APP_APIHOST : process.env.REACT_APP_APIHOST,
    driveshost: process.env.NODE_ENV === 'development' ? 'http://localhost' + process.env.REACT_APP_APIHOST + process.env.REACT_APP_DRIVESHOST : process.env.REACT_APP_APIHOST + process.env.REACT_APP_DRIVESHOST,
    adminhost: process.env.NODE_ENV === 'development' ? 'http://localhost' + process.env.REACT_APP_ADMINHOST : process.env.REACT_APP_ADMINHOST,
    qliksensehost: process.env.REACT_APP_QLIKSENSEHOST,
    matomohost: process.env.REACT_APP_MATOMOHOST,
    websocket_protocol: process.env.NODE_ENV === 'development' ? 'ws' : process.env.REACT_APP_WEBSOCKET_PROTOCOL,
    websockethost: window.location.hostname + process.env.REACT_APP_WEBSOCKETHOST,
};

export default function(state = initialState, action) {
    return state;
}
