import React from 'react';
import ImageDisplay from '../ImageDisplay';

function RenderImageUpload({ input, meta: { invalid, touched, error }, ...custom }) {
	return (
		<div>
			<ImageDisplay
				label={custom.inputdata.label}
				onRef={custom.onRef}
				id={custom.inputdata.id}
				accept={custom.inputdata.accept ? custom.inputdata.accept : 'image/*'}
				name={custom.inputdata.name}
				onChange={(value) => {
					input.onChange(value);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(value);
					}
				}}
				error={error}
				invalid={invalid}
				validate={custom.inputdata.validate}
				alert={custom.inputdata.alert}
				initialFiles={custom.inputdata.initialFiles}
				limitFiles={custom.inputdata.limitFiles}
				uploadEnabled={typeof custom.inputdata.uploadEnabled === 'undefined' || custom.inputdata.uploadEnabled ? true : false}
			/>
		</div>
	);
}

export default RenderImageUpload;
