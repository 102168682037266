import {
	FETCH_LIBRARY,
	FETCH_LIBRARY_DRAFTS,
	HANDLE_LIBRARY_LOADING,
	FETCH_LIBRARY_FILE,
	FETCH_LIBRARY_FILE_DATA,
	FETCH_LIBRARY_FOLDER,
	CLEAR_LIBRARY_FOLDER,
	NEW_LIBRARY_FOLDER,
	NEW_LIBRARY_DRIVE,
	NEW_LIBRARY_FILE,
	FETCH_LIBRARY_DRIVE,
	DELETE_LIBRARY_FILE,
	DELETE_LIBRARY_FOLDER,
	DELETE_LIBRARY_DRIVE,
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	data: [],
	drafts: [],
	total_count: null,
	file: {
		mime_type: 'pdf',
	},
	folder: {},
	drive: {},
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_LIBRARY:
			return {
				...state,
				data: action.payload.data,
				drafts: action.payload.drafts,
				total_count: action.payload.total_count,
			};
		case FETCH_LIBRARY_DRAFTS:
			return {
				...state,
				drafts: action.payload.data,
			};
		case FETCH_LIBRARY_FILE:
			return {
				...state,
				file: action.payload,
			};
		case FETCH_LIBRARY_FILE_DATA:
			return {
				...state,
				file: { ...state.file, ...action.payload },
			};
		case FETCH_LIBRARY_FOLDER:
			return {
				...state,
				folder: action.payload,
			};
		case FETCH_LIBRARY_DRIVE:
			return {
				...state,
				drive: action.payload,
			};
		case NEW_LIBRARY_FILE:
			return {
				...state,
				file: { ...initialState.folder, ...action.payload },
			};
		case NEW_LIBRARY_FOLDER:
			return {
				...state,
				folder: { ...initialState.folder, ...action.payload },
			};
		case NEW_LIBRARY_DRIVE:
			return {
				...state,
				drive: {},
			};
		case HANDLE_LIBRARY_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case DELETE_LIBRARY_FILE:
			return {
				...state,
				file: initialState.file,
			};
		case DELETE_LIBRARY_FOLDER:
			return {
				...state,
				folder: initialState.folder,
			};
		case DELETE_LIBRARY_DRIVE:
			return {
				...state,
				drive: initialState.drive,
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
