import NettoWebFont from './fonts/NettoWeb.woff';
import RobotoRegularFont from './fonts/Roboto-Regular.ttf';
import RobotoBoldFont from './fonts/Roboto-Bold.ttf';
import WorkSansFont from './fonts/work-sans-v11-latin-regular.woff';
import GlyphiconsHalflingsFont from './fonts/glyphicons-halflings-regular.woff';

import ChronicleDeck_Black_Font from './fonts/ChronicleDeck/ChronicleDeck-Black.otf';
import ChronicleDeck_BlackItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-BlackItalic.otf';
import ChronicleDeck_Bold_Font from './fonts/ChronicleDeck/ChronicleDeck-Bold.otf';
import ChronicleDeck_BoldItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-BoldItalic.otf';
import ChronicleDeck_Italic_Font from './fonts/ChronicleDeck/ChronicleDeck-Italic.otf';
import ChronicleDeck_Roman_Font from './fonts/ChronicleDeck/ChronicleDeck-Roman.otf';
import ChronicleDeck_SemiBold_Font from './fonts/ChronicleDeck/ChronicleDeck-Semibold.otf';
import ChronicleDeck_SemiItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-Semitalic.otf';

import ChronicleText_Bold_Font from './fonts/ChronicleText/ChronicleTextG1-Bold-Pro.otf';
import ChronicleText_BoldItalic_Font from './fonts/ChronicleText/ChronicleTextG1-BoldIta-Pro.otf';
import ChronicleText_Italic_Font from './fonts/ChronicleText/ChronicleTextG1-Italic-Pro.otf';
import ChronicleText_Roman_Font from './fonts/ChronicleText/ChronicleTextG1-Roman-Pro.otf';
import ChronicleText_SemiBold_Font from './fonts/ChronicleText/ChronicleTextG1-Semibd-Pro.otf';
import ChronicleText_SemiItalic_Font from './fonts/ChronicleText/ChronicleTextG1-SemiIta-Pro.otf';

import Maax_Bold_Italic_Font from './fonts/Maax/Maax-Bold-Italic-205TF.woff';
import Maax_Bold_Font from './fonts/Maax/Maax-Bold-205TF.woff';
import Maax_Italic_Font from './fonts/Maax/Maax-Italic-205TF.woff';
import Maax_Regular_Font from './fonts/Maax/Maax-Regular-205TF.woff';

export const NettoWeb = {
	fontFamily: 'NettoWeb',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${NettoWebFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoRegular = {
	fontFamily: 'RobotoRegular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoRegularFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoBold = {
	fontFamily: 'RobotoBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoBoldFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const WorkSans = {
	fontFamily: 'WorkSans',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${WorkSansFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const GlyphiconsHalflings = {
	fontFamily: 'GlyphiconsHalflings',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${GlyphiconsHalflingsFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Black = {
	fontFamily: 'ChronicleDeck_Black',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Black_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_BlackItalic = {
	fontFamily: 'ChronicleDeck_BlackItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_BlackItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Bold = {
	fontFamily: 'ChronicleDeck_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Bold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_BoldItalic = {
	fontFamily: 'ChronicleDeck_BoldItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_BoldItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Italic = {
	fontFamily: 'ChronicleDeck_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Italic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Roman = {
	fontFamily: 'ChronicleDeck_Roman',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Roman_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_SemiBold = {
	fontFamily: 'ChronicleDeck_SemiBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_SemiBold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_SemiItalic = {
	fontFamily: 'ChronicleDeck_SemiItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_SemiItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Bold = {
	fontFamily: 'ChronicleText_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Bold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_BoldItalic = {
	fontFamily: 'ChronicleText_BoldItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_BoldItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Italic = {
	fontFamily: 'ChronicleText_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Italic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Roman = {
	fontFamily: 'ChronicleText_Roman',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Roman_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_SemiBold = {
	fontFamily: 'ChronicleText_SemiBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_SemiBold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_SemiItalic = {
	fontFamily: 'ChronicleText_SemiItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_SemiItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Bold_Italic = {
	fontFamily: 'Maax_Bold_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Bold_Italic_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Bold = {
	fontFamily: 'Maax_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Bold_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Italic = {
	fontFamily: 'Maax_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Italic_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Regular = {
	fontFamily: 'Maax_Regular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Regular_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};