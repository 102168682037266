import { WHOAMI, MENU_ACTIVE_ACTION } from '../actions/types';

const initialState = {
	whoami: '',
	ward: '',
	menu: ''
};

export default function(state = initialState, action) {
	switch (action.type) {
		case WHOAMI:
			return {
				...state,
				whoami: action.payload
			};
		case MENU_ACTIVE_ACTION:
			return {
				...state,
				menu: action.payload.menu
			};
		default:
			return state;
	}
}
