import { Component } from 'react';
import { withIdleTimer } from 'react-idle-timer';

class IdleTimerProvider extends Component {
	render() {
		return this.props.children;
	}
}

export default withIdleTimer(IdleTimerProvider);
