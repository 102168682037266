import { convertHex } from '../../helpers/functions/functions';

const libraries = (theme) => {
	return {
		'.libraries-tree-item-icon': {
			fontSize: '30px !important',
			width: theme.spacing(4),
		},
		'.libraries-management-tree-item-icon': {
			fontSize: '30px !important',
			color: theme.palette.primary.main,
			width: theme.spacing(4),
		},
		'.libraries-tree-item-icon-svg': {
			fontSize: 'inherit !important',
		},
		'.libraries-tree-item-root': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,
		},
		'.libraries-tree-item-content-folder': {
			color: 'white',
			height: theme.spacing(4),
			backgroundColor: theme.palette.primary.main,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(1),
		},
		'.libraries-management-tree-item-content-folder': {
			height: theme.spacing(4),
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(0),
			border: '2px solid #e6e6e6',
		},
		'.libraries-management-tree-drive-item-content-folder': {
			height: theme.spacing(4),
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(0),
			border: '2px solid #e6e6e6',
			backgroundColor: convertHex(theme.palette.primary.main, 5),
		},
		'.libraries-tree-item-files-label': {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0.5, 0),
			// '&:hover': {
			// 	backgroundColor: 'white'
			// },
		},
		'.libraries-tree-item-files-label-text': {
			flexGrow: 1,
		},
		'.libraries-tree-item-files-label-text-code-error': {
			flexGrow: 1,
			textDecoration: 'line-through',
		},
		'.libraries-tree-item-files-edit-icon .MuiTreeItem-iconContainer': {
			width: '36px',
			marginLeft: '-20px'
		},
		'.libraries-tree-item-files-label-chip': {
			borderRadius: theme.spacing(0.5),
			maxWidth: '-webkit-fill-available',
		},
		'.libraries-tree-item-files-label-spacing': {
			width: '10%',
		},
		'.libraries-tree-item-selected': {
			backgroundColor: 'none',
		},
		'.libraries-tree-item-label': {
			'&:hover': {
				backgroundColor: 'transparent',
			},
			'&:focus': {
				// CHECK THIS
				backgroundColor: 'transparent',
			},
		},

		'.libraries-folder-form-container': {
			backgroundColor: '#e6e6e642',
			borderRadius: theme.spacing(0.5),
			marginTop: theme.spacing(1),
		},
		'.libraries-folder-form-button': {
			margin: theme.spacing(2, 0, 2),
		},
		'.libraries-drafts-list': {
			marginTop: theme.spacing(2),
			backgroundColor: '#e6e6e642',
		},
	};
};

export default libraries;
