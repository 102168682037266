const chat = theme => {
	return {
		'.chat-main': {
			// margin: theme.spacing(2, 2)
			height: '100%'
		},
		'.chat-messages-content': {
			paddingBottom: '0px',
			overflow: 'auto',
			width: '100%',
			backgroundColor: theme.palette.background.paper
		},
		'.chat-messages-item': {
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.07)'
			}
		},
		'.chat-message-avatar-system': {
			color: '#fff',
			backgroundColor: theme.palette.primary.main
		},
		'.chat-message-avatar-doctor': {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main
		},
		'.chat-message-avatar-patient': {
			color: theme.palette.secondary.main,
			backgroundColor: '#dddddd'
		},
		'.chat-message-input-icon': {
			borderRadius: 'unset',
			'&:hover': {
				opacity: '0.8'
			}
		},
		'.chat-message-input-field': {
			marginTop: '0px'
		},
		'.boga': {
			height: '15%'
		},
		'.chat-message-input-field textarea': {
			minHeight: '20px !important'
		},
		'.chat-message-link': {
			cursor: 'pointer',
			display: 'block'
		},
		'.chat-message-icon-button': {
			marginRight: theme.spacing(1)
		},
		'.notification': {
			width: '50px',
			height: 'inherit',
			color: theme.palette.primary.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			'&:before': {
				content: "'\\f0f3'",
				fontFamily: 'FontAwesome',
				display: 'block'
			},
			'&:after': {
				minWidth: '20px',
				height: '20px',
				content: 'attr(data-count)',
				backgroundColor: '#ed657d',
				fontFamily: 'monospace',
				fontWeight: 'bolt',
				fontSize: '14px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				position: 'absolute',
				top: '5px',
				right: '5px',
				transition: '.3s',
				opacity: '0',
				transform: 'scale(.5)',
				willChange: 'opacity, transform'
			},
			'&.notify': {
				'&:before': {
					animation: 'bell 1s ease-out',
					transformOrigin: 'center top'
				}
			},
			'& .show-count': {
				'&:after': {
					opacity: '1',
					transform: 'scale(1)'
				}
			}
		},
		'.notification-mention': {
			color: theme.palette.error.main,
		},
    '.notify': {
        animation: 'bell 1s ease-out',
        transformOrigin: 'right top'
    },
		'@keyframes bell': {
			'0%': { transform: 'rotate(35deg)' },
			'12.5%': { transform: 'rotate(-30deg)' },
			'25%': { transform: 'rotate(25deg)' },
			'37.5%': { transform: 'rotate(-20deg)' },
			'50%': { transform: 'rotate(15deg)' },
			'62.5%': { transform: 'rotate(-10deg)' },
			'75%': { transform: 'rotate(5deg)' },
			'100%': { transform: 'rotate(0)' }
		}
	};
};

export default chat;
