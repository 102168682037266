import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Link } from '@material-ui/core';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

class NavBar extends Component {
	render() {
		const { translate } = this.props;
		
		return (
			<AppBar
				variant={
					typeof this.props.settings.styling !== 'undefined' && !this.props.settings.styling.navbar_shadow
						? 'outlined'
						: 'elevation'
				}
				className={this.props.disabledPositionFixed ? 'register-navbar-main' : 'welcome-navbar-main'}
				position="static"
			>
				<Toolbar variant="dense">
					<IconButton edge="start" className="generic-navbar-main-logo" color="inherit" aria-label="Menu" href="/">
						<img
							src={
								typeof this.props.settings.site !== 'undefined' && this.props.settings.site.files.navbar_logo
									? this.props.config.apihost + '/configuration/files/' + this.props.settings.site.files.navbar_logo.uuid
									: PlaceholderIMG
							}
							height="35"
							alt="Alteri's logo"
						/>
					</IconButton>
					<div className="flexGrow" />
					{this.props.location.pathname.includes('meetings') && (
							<Button disabled className={'navbar-links-disabled-button generic-navbar-section-active'} color="inherit">
								{translate('navbar_button_disabled_meeting')}
							</Button>
						)}
					<Link component={RouterLink} to={'/login'} color="inherit" underline="none">
						<Button color="inherit">
							<Typography align="left" variant="subtitle2" m="5" color="primary">
								{translate('welcome_navbar_login_button')}
							</Typography>
						</Button>
					</Link>
				</Toolbar>
			</AppBar>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	settings: state.settings,
	config: state.config,
});

export default connect(mapStateToProps)(withRouter(NavBar));
