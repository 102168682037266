const tui_editor = (theme) => {
	return {
		'.tui-toolbar-button-attach': {
			height: 'inherit',
			color: theme.palette.primary.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			'&:before': {
				content: "'\\f0c6'",
				fontFamily: 'FontAwesome',
				display: 'block',
			},
		},
		'.tui-toolbar-button-send': {
			height: 'inherit',
			color: theme.palette.primary.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			'&:before': {
				content: "'\\f124'",
				fontFamily: 'FontAwesome',
				display: 'block',
			},
		},
	};
};

export default tui_editor;