import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Gallery from 'react-grid-gallery';
import { Button, Grid, FormHelperText, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Delete as DeleteIcon, ZoomIn as ZoomInIcon } from '@material-ui/icons';
import pdf_image from '../../assets/images/pdf.jpg';
import player_image from '../../assets/images/player_thumbnail.jpeg';
import document_image from '../../assets/images/document_thumbnail.png';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { addWrapper } from '../../components/Generic/actions/genericActions';
import Player from '../../components/Generic/Player';
import { openPdfFIle } from '../../helpers/functions/functions';
import { Alert } from '@material-ui/lab';

/** DOCS:
 * props:
 * id
 * onChange
 * initialFiles
 * limitFiles
 */

class ImageDisplay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: false,
			values: [],
			preview: [],
			selected: [],
			lightboxOpen: false,
			disableZoomButton: false,
			modal_player: {
				open: false,
				url: '',
			},
		};

		this.galleryRef = React.createRef();

		// this.onClickThumbnail = this.onClickThumbnail.bind(that);
	}

	componentDidMount() {
		if (typeof this.props.initialFiles !== 'undefined' && this.props.initialFiles.length > 0) {
			this.props.initialFiles.forEach((initialFile) => {
				fetch(initialFile)
					.then((resp) => resp.blob())
					.then((blob) => {
						this.setState({
							values: [...this.state.values, blob],
						});
					})
					.catch(() => alert('oh no!'));
			});
		}
		if (typeof this.props.onRef !== 'undefined') {
			this.props.onRef(this);
		}
	}

	componentWillUnmount() {
		if (typeof this.props.onRef !== 'undefined') {
			this.props.onRef(null);
		}
	}

	resetImageDisplayState() {
		this.setState({
			edit: false,
			values: [],
			preview: [],
			selected: [],
			lightboxOpen: false,
			disableZoomButton: false,
		});
	}

	handleLightBoxWillClose = () => {
		this.setState(
			{
				lightboxOpen: true,
				disableZoomButton: false,
			},
			() => {
				this.setState({
					lightboxOpen: false,
				});
			}
		);
	};

	addZoomFeature = () => {
		this.setState({
			disableZoomButton: true,
		});

		var el = document.querySelector('[class*=figure_]');
		var parent = document.querySelector('.' + this.props.id + ' .react-transform-component');
		var child = document.querySelector('.' + this.props.id + ' .react-transform-element');

		var newWrapper = parent.cloneNode(true);
		document.getElementsByClassName(this.props.id)[0].appendChild(newWrapper);

		if (!parent) {
			return false;
		}

		el.parentNode.insertBefore(child, el);

		child.appendChild(el);

		child.parentNode.insertBefore(parent, child);

		parent.appendChild(child);
	};

	handleEditImages = (id) => {
		this.setState({
			edit: !this.state.edit,
		});
	};

	handleSelectImage = (id, index, file_name) => {
		this.setState({
			selected: this.state.selected.includes(file_name)
				? this.state.selected.filter((selected) => selected !== file_name)
				: this.state.selected.concat(file_name),
		});
	};

	handleAddImage = (e, id, onChange) => {
		var file = e.target.files[0];
		if (this.state.values.some((value) => value.name == file.name)) {
			file = new File([file], '(copy ' + this.state.values.length + ')' + file.name, { type: file.type });
		}

		var values = [file, ...this.state.values];

		this.setState({
			values: values,
		});

		document.getElementsByTagName('INPUT')[this.props.id].value = '';

		onChange(values);
	};

	handleRemoveImages = (id, onChange) => {
		var values = this.state.values.filter((value) => !this.state.selected.includes(value.name));

		this.setState({
			values: values,
			selected: [],
		});
		onChange(values);
	};

	handleRemoveImage = (id, index, file_name, onChange) => {
		var values = this.state.values.filter((value) => value.name !== file_name);

		this.setState({
			values: values,
			selected: [],
		});
		onChange(values);
	};

	styleSmall = () => {
		return {
			position: 'absolute',
			left: '50%',
			top: '50%',
			height: 'auto',
			width: '100%',
			transform: 'translate(-50%,-50%)',
			cursor: 'pointer',
		};
	};

	handleImageSize = ({ target: img }) => {
		img.style.height = img.naturalWidth > img.naturalHeight ? 'auto' : '100%';
		img.style.width = img.naturalWidth > img.naturalHeight ? '100%' : 'auto';
	};

	handleImageThumbnailComponent = ({ imageProps }) => {
		return <img src={imageProps.src} onLoad={this.handleImageSize} key={imageProps.key} alt="" style={imageProps.style} />;
	};

	onClickImage = () => {
		return false;
	};

	onClickThumbnail = (index, event) => {
		let fileType = this.galleryRef.current ? this.galleryRef.current.props.images[index].type.split('/')[0] : '';

		switch (fileType) {
			case 'image':
				this.galleryRef.current.openLightbox(index, event);
				break;
			case 'video':
				this.setState({
					modal_player: {
						open: true,
						url: this.galleryRef.current.props.images[index].blob,
					},
				});
				break;
			case 'application':
				openPdfFIle(this.galleryRef.current.props.images[index].blob);
				break;
			default:
				break;
		}
	};

	render() {
		const { translate } = this.props;
		let resetZoom = null;
		const previewsImages = this.state.values.map((file) => {
			if (typeof file === 'undefined') {
				return null;
			}
			var blob = URL.createObjectURL(file);

			let thumbnail = document_image;

			switch (file.type.split('/')[0]) {
				case 'application':
					if (file.type.split('/')[1] === 'pdf') {
						thumbnail = pdf_image;
					}
					break;
				case 'video':
					thumbnail = player_image;
					break;
				case 'image':
					thumbnail = blob;
					break;

				default:
					break;
			}

			return {
				name: file.name,
				src: thumbnail,
				thumbnail: thumbnail,
				thumbnailWidth: 0,
				thumbnailHeight: 0,
				isSelected: this.state.selected.includes(file.name),
				type: file.type,
				blob: blob,
				orientation: 1,
			};
		});

		return (
			<div>
				{!this.state.lightboxOpen && (
					<div className={this.props.id}>
						<TransformWrapper>
							{({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => {
								if (resetZoom === null) {
									resetZoom = resetTransform;
								}
								return <TransformComponent></TransformComponent>;
							}}
						</TransformWrapper>
					</div>
				)}
				{(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
				(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles) ? (
					''
				) : (
					<Typography id="continuous-slider" gutterBottom>
						{this.props.label}
					</Typography>
				)}
				{typeof this.props.uploadEnabled !== 'undefined' && this.props.uploadEnabled ? (
					<Grid container>
						<Grid item>
							<input
								name={this.props.id}
								style={{ display: 'none' }}
								accept={this.props.accept ? this.props.accept : 'image/*'}
								id={this.props.id}
								multiple
								type="file"
								onChange={(e) => this.handleAddImage(e, this.props.id, this.props.onChange)}
								disabled={
									(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
									(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles)
								}
							/>
							<label for={this.props.id}>
								<Grid container>
									<Grid item sm={12}>
										<Gallery
											ref={this.galleryRef}
											lightboxWillClose={this.handleLightBoxWillClose}
											images={previewsImages}
											enableImageSelection={typeof this.props.uploadEnabled !== 'undefined' && this.props.uploadEnabled}
											onSelectImage={(index, image) => {
												this.handleRemoveImage(this.props.id, index, image.name, this.props.onChange);
											}}
											thumbnailStyle={this.styleSmall}
											thumbnailImageComponent={this.handleImageThumbnailComponent}
											customControls={[
												<Button
													disabled={this.state.disableZoomButton}
													startIcon={<ZoomInIcon />}
													variant="contained"
													className={'lightbox-header-button-zoom'}
													onClick={this.addZoomFeature}
												>
													{translate('lightbox_enable_zoom')}
												</Button>,
											]}
											onClickImage={this.onClickImage}
											currentImageWillChange={() => resetZoom()}
											onClickThumbnail={this.onClickThumbnail}
										/>
									</Grid>
									<Grid item sm={12}>
										<Typography>{this.state.values.map((test) => test.name)} </Typography>
									</Grid>
									<Grid item sm={12}>
										<Button
											variant="contained"
											color="primary"
											component="span"
											disabled={
												(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
												(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles)
											}
										>
											{translate('generic_button_upload')}
										</Button>
									</Grid>
								</Grid>
							</label>
						</Grid>

						{/* <Grid item>
							<IconButton
								id={this.props.id}
								disabled={!this.state.selected.length > 0}
								aria-label="delete"
								onClick={() => this.handleRemoveImages(this.props.id, this.props.onChange)}
								style={{ visibility: 'hidden' }}
							>
								<DeleteIcon fontSize="large" />
							</IconButton>
						</Grid> */}
					</Grid>
				) : null}
				{this.props.validate && this.props.invalid && this.props.error ? (
					<FormHelperText error> {this.props.error}</FormHelperText>
				) : this.props.alert ? (
					<Grid item className="image-display-alert-message-grid">
						<Alert className="event-form-info" variant="outlined" severity="info">
							{translate('form_field_file_max_size_allowed') +
								': ' +
								this.props.validate.maxSizeFile +
								' ' +
								translate('form_field_file_type_not_allowed') +
								': .bin'}
						</Alert>
					</Grid>
				) : (
					''
				)}

				<Player
					open={this.state.modal_player.open}
					url={this.state.modal_player.url}
					onClose={() => this.setState({ modal_player: { ...this.props.modal_player, open: false } })}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	config: state.config,
	login: state.login,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, { addWrapper })(ImageDisplay);
