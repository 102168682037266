import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { TableRow, TableCell } from '@material-ui/core';
import { Table } from '../Generic';

import { fetchTicketAction, claimTicketAction, handleIntiveTicketAction, verifyTicketModalAction } from '../Tickets/actions/ticketsActions';
import { displayNotificationsModalAction, displayModalAction } from './actions/genericActions';
import TICKET from '../../helpers/ticket';
import StatusChip from '../Tickets/StatusChip';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import { isMobileSize, getByTimezone, formatLocaleDate } from '../../helpers/functions/functions';
import { DoneAll as DoneAllIcon, NotificationsActive as NotificationsActiveIcon } from '@material-ui/icons';
import TABLES from '../../helpers/tables';
import MESSAGES from '../../helpers/messages';
import { SocketContext } from '../Chat/SocketProvider';

class NotificationsTable extends Component {
  static contextType = SocketContext;
	//     automatic: "0"
	// channels_id: "1"
	// condition_title: "Developer Test Condition"
	// created: "2020-12-02 17:05:53"
	// display_id: "ac12"
	// id: "2"
	// link: "/tickets/12/files/1"
	// link_type: "file"
	// sender_id: "8"
	// sender_name: "Postmaster"
	// sender_role: "system"
	// text: "event_file_notification_created"
	// ticket_id: "12"
	// type: "notification"
	// unread: "1"

	handleTableConfig = () => {
		const { timezone } = this.props.whoami;

		return [
			{
				column: 'id',
				label: 'id',
				display: 'hidden',
				options: {
					filter: false,
				},
			},
			{
				column: 'ticket_id',
				label: 'ticket_id',
				display: 'hidden',
				options: {
					filter: false,
				},
			},
			{
				column: 'created',
				display: 'text',
				label: this.props.translate('notifications_list_column_created'),
				options: {
					filter: false,
					sort: false,
					customBodyRender: (value, tableMeta, updateValue) =>
						formatLocaleDate(getByTimezone(value, timezone, 'YYYY-MM-DD HH:mm'), this.props.whoami.language),
					display: true,
				},
			},
			{
				column: 'text',
				display: 'text',
				label: this.props.translate('notifications_list_column_message'),
				options: {
					sort: false,
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						return this.props.translate(value);
					},
					display: true,
				},
			},
			{
				column: 'condition_title',
				display: 'text',
				label: this.props.translate('notifications_list_column_condition'),
				options: {
					filter: false,
					sort: false,
					display: !isMobileSize() ? true : 'excluded',
					renderOnExpand: !isMobileSize() ? false : 'expand',
				},
			},
			{
				column: 'unread',
				display: 'hidden',
				label: 'unread_value',
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				column: 'unread',
				display: 'text',
				label: this.props.translate('notifications_list_column_read'),
				options: {
					sort: false,
					customBodyRender: this.unreadColumnRender,
					customFilterListOptions: {
						render: this.unreadColumnRender,
					},
					filterType: 'checkbox',
					filterOptions: {
						names: ['0', '1'],
						renderValue: (v) => (v ? this.unreadColumnRender(v) : ''),
					},
					display: true,
				},
			},
			{
				column: 'ticket_status',
				display: 'text',
				label: this.props.translate('notifications_list_column_status'),
				options: {
					sort: false,
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => <StatusChip status={value} value={value} type={''} />,
					display: !isMobileSize() ? true : 'excluded',
					renderOnExpand: !isMobileSize() ? false : 'expand',
				},
			},
		];
	};

	unreadColumnRender = (value) => {
		return value === '0' ? <DoneAllIcon className="notification-table-read-icon" /> : <NotificationsActiveIcon color="error" />;
	};

	displayNoAccessModal = (ticket) => {
		const { translate } = this.props;

		this.props.displayModalAction({
			title: translate('ticket_list_access_denied_modal_title'),
			description: ticket.message
				? translate('ticket_list_access_denied_modal_description_with_message', {
						message: ticket.message,
				  })
				: translate('ticket_list_access_denied_modal_description_without_message'),
			button_no: translate('generic_button_close'),
		});
	};

  displayUnknownTicketModal = (message) => {
		const { translate } = this.props;
		
    if (message) {
      if (message.unread != '0') {
        this.handleSetMessagesRead(message);	
      }
    }

    this.props.displayModalAction({
			title: translate('ticket_list_unknown_modal_title'),
			description: translate('ticket_list_unknown_modal_description_with_message', {
						message: message.text,
				  }),
			button_no: translate('generic_button_close'),
		});
	};

	handleFetchTicket = (ticket, message_link_type = null, message = null) => {
		const { translate } = this.props;

		if (!ticket.scopes.includes(TICKET.SCOPES.MANAGE.VIEW)) {
			switch (Number(ticket.status)) {
				case TICKET.STATUS.REJECTED:
				case TICKET.STATUS.WAITING:
					if (this.props.whoami.scopes.includes('practitioner') && ticket.patient.id != this.props.whoami.id) {
						this.props.displayModalAction({
							title: translate('ticket_list_claim_modal_title'),
							description: translate('ticket_list_claim_modal_description'),
							button_no: translate('ticket_list_claim_modal_disagree'),
							button_yes: translate('ticket_list_claim_modal_agree'),
							button_yes_id: 'ticket-claim-modal-button',
							action: this.props.claimTicketAction,
							props: ticket.id,
						});
					} else {
						this.displayNoAccessModal(ticket);
            if (message) {
              if (message.unread != '0') {
                this.handleSetMessagesRead(message);	
              }
            }
					}
					break;
				case TICKET.STATUS.PENDING:
					if (ticket.patient.id == this.props.whoami.id) {
            let now = new Date();
            if (ticket.answering_expires_at){
              if ( new Date(ticket.answering_expires_at.replace(/ /g,"T")) < now) {
                if (message) {
                  if (message.unread != '0') {
                    this.handleSetMessagesRead(message);	
                  }
                }
                this.props.displayModalAction({
                  title: translate('ticket_list_followup_expired_flow_modal_title'),
                  description: translate('ticket_list_followup_expired_flow_modal_description_with_message', {
                    activation: ticket.activation,
                    message: ticket.message,
                  }),
                  button_no: translate('generic_button_close'),
                });
                break;
              }
            }
						this.props.displayModalAction({
							title: translate('ticket_list_followup_flow_modal_title'),
							description: translate('ticket_list_followup_flow_modal_description_with_message', {
								activation: ticket.activation,
								message: ticket.message,
							}),
							button_no: translate('ticket_list_followup_flow_modal_disagree'),
							button_yes: translate('ticket_list_followup_flow_modal_agree'),
							action: this.props.handleIntiveTicketAction,
							props: ticket.id,
						});
					} else {
						this.displayNoAccessModal(ticket);
            if (message) {
              if (message.unread != '0') {
                this.handleSetMessagesRead(message);	
              }
            }
					}
					break;
				case TICKET.STATUS.UNVERIFIED:
					if (ticket.scopes.includes('tickets.manage.verify')) {
						this.props.displayModalAction({
							title: translate('ticket_list_unverified_modal_title'),
							description: translate('ticket_list_unverified_modal_description_with_message', {
								reference: ticket.reference,
							}),
							button_no: translate('ticket_list_unverified_modal_goto'),
							button_yes: translate('ticket_list_unverified_modal_verify'),
							action_no_button: () => {
								this.props.fetchTicketAction(ticket.id);
								this.props.push('case');
							},
							action: this.props.verifyTicketModalAction,
							props: ticket.id,
						});
          } else {
            this.displayNoAccessModal(ticket);
            if (message) {
              if (message.unread != '0') {
                this.handleSetMessagesRead(message);	
              }
            }
					}
					break;
				default:
					this.displayNoAccessModal(ticket);
          if (message) {
            if (message.unread != '0') {
              this.handleSetMessagesRead(message);	
            }
          }
					break;
			}
		} else {
			this.props.fetchTicketAction(ticket.id);

      //still to be added if needed: 'tasks','appointments','certificates','notes', 'diagnoses'
      switch (message.ref_type) {
        case MESSAGES.REF.TICKETS_QUESTIONNAIRES:
          this.props.push('case#questionnaires');
          break;
        case MESSAGES.REF.TICKETS_FILES:
          //this.props.push('case');
          this.props.push('case#files');
          break;
        case MESSAGES.REF.TICKETS_PRESCRIPTIONS:
            this.props.push('case#prescriptions');
            break;
        case MESSAGES.REF.TICKETS_FOLLOWUP:
          if (message.origin_id == message.ref_id) {
            this.props.push('case#messages');
          } else {
            this.props.push('case#followups');
          }
          break;
        case MESSAGES.REF.TICKETS_MEETINGS:
          this.props.push('case#meetings');
          break;
        case MESSAGES.REF.TICKETS_ACCESS:
          this.props.push('case#team');
          break;   
        case MESSAGES.REF.CHAT_MESSAGES:
          this.props.push('case#messages');
          break;        
        default:
          if (message_link_type === 'appointment') {
            this.props.push('case#video');
          } else {
            this.props.push('case');
          }
          break;
      }
		}
	};

  handleSetMessagesRead = (message) => {
		if (typeof message !== 'undefined') {
			if (this.sendMessage(
				JSON.stringify({
					type: 'readmark',
					link_type: 'readmark',
					origin_type: message.origin_type,
					origin_id: message.origin_id,
					ref_type: message.ref_type,
					ref_id: message.ref_id,
					text: 'event_ticket_read',
				})
			)){
				return true;
			}
		}
		
	};

  sendMessage = (data) => {
		try {
			if (this.context.ws) {
				this.context.ws.send(data, 'something');
				return true;
			}
		} catch (error) {
			console.log(error); // catch error
		}
    return false;
	};

	onRowClick = (rowMeta, data) => {
		if (data.length === 0) {
			return null;
		} else {
			if (this.props.notifications_modal.open) {
				this.props.displayNotificationsModalAction(false);
			}

			let message = this.props.messages.find((field) => field.id === data[rowMeta.dataIndex].id);
      this.props.fetchTicketAction(data[rowMeta.dataIndex].ticket_id, false, (ticket) => this.handleFetchTicket(ticket, message.link_type, message), (ticket) => this.displayUnknownTicketModal(message));
    }
	};

	setRowProps = (row, columns) => {
		let nextEventIndex = columns.findIndex((field) => field.name === 'unread_value');

		if (row[nextEventIndex] == '0') {
			return {
				//   style: { background: "snow" }
				className: 'activity-no-event-row-background-color',
			};
		}
	};

	renderExpand = (rowMeta, data, rowData, columns) => {
		const colSpan = rowData.length;
		return (
			<React.Fragment>
				{Object.keys(data[rowMeta.rowIndex]).map((entry) => {
					let field = columns.find((field) => field.name === entry);
					if (field.options.renderOnExpand) {
						return (
							<TableRow>
								<TableCell></TableCell>
								<TableCell variant="header" align="left">
									{field.label}
								</TableCell>
								<TableCell colSpan={colSpan} align="left">
									{field.options.customBodyRender
										? field.options.customBodyRender(data[rowMeta.rowIndex][entry])
										: data[rowMeta.rowIndex][entry]}
								</TableCell>
							</TableRow>
						);
					}
				})}
			</React.Fragment>
		);
	};

	render() {
		const { translate } = this.props;

		var columns = [];

		this.handleTableConfig().forEach((field) =>
			columns.push({
				name: field.label,
				label: field.label,
				options: {
					display: field.display === 'hidden' ? false : true,
					...field.options,
				},
			})
		);

		return (
			<Table
				tableId={TABLES.NOTIFICATIONS}
				config={this.handleTableConfig()}
				columns={columns}
				data={this.props.messages}
				onRowClick={this.onRowClick}
				{...(isMobileSize() && {
					onRowExpand: (rowMeta, data, rowData) => this.renderExpand(rowMeta, data, rowData, columns),
				})}
				serverSide={false}
				setRowProps={(row) => this.setRowProps(row, columns)}
				disableFilters={this.props.disableFilters ? true : false}
				disableSearchs={this.props.disableSearchs ? true : false}
			></Table>
		);
	}
}

const mapStateToProps = (state) => ({
	whoami: state.users.whoami,
	translate: getTranslate(state.localize),
	messages: state.users.whoami.scopes.includes('lists.cross_client') ? state.websockets[NOTIFICATIONS].messages : state.websockets[NOTIFICATIONS].messages.filter((msg) => msg.ticket_client_id == state.users.whoami.current_client),
	notifications_modal: state.generic.notifications_modal,
});

export default connect(mapStateToProps, {
	push,
	fetchTicketAction,
	displayNotificationsModalAction,
	displayModalAction,
	claimTicketAction,
	handleIntiveTicketAction,
	verifyTicketModalAction,
})(NotificationsTable);
