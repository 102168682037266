import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Box, Link } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { HtmlRender } from './';

function MessageDisplay(props) {
	return (
		<div>
			<Typography align="center" variant="h2" component="h2">
				{props.title}
			</Typography>
			<Typography align="center" variant="subtitle2" component="p" gutterBottom>
				{props.subtitle}
			</Typography>
			<Box mt={3} mb={3}>
				{props.htmlRender ? (
					<HtmlRender textToParse={props.text} />
				) : (
					<Typography align="center" paragraph={true} component="h6" variant="h6">
						{props.text.split('\n').map((item, key) => {
							return (
								<React.Fragment key={key}>
									{item.split(' ').map((text) => {
										return text.includes('http') ? (
											<Link href={text} target="_blank" rel="noreferrer">
												{text}{' '}
											</Link>
										) : (
											text + ' '
										);
									})}

									<br />
								</React.Fragment>
							);
						})}
					</Typography>
				)}
			</Box>
			{props.actionButton && (
				<div className="generic-messagedisplay-button-go-to-case">
					<Button size="small" variant="contained" color="primary" onClick={() => props.actionButton()}>
						{props.actionButtonText}
						<ArrowForwardIosIcon className="rightIcon" />
					</Button>
				</div>
			)}
			<div className="generic-messagedisplay-button-bar">
				<Link component={RouterLink} to={props.redirectPath} underline="none">
					<Button size="small" variant="contained" color="primary">
						{props.buttonText}
						<ArrowForwardIosIcon className="rightIcon" />
					</Button>
				</Link>
			</div>
		</div>
	);
}

export default MessageDisplay;
