import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withIdleTimer } from 'react-idle-timer';
import IdleTimerProvider from './IdleTimerProvider';
import { logoutAction, refreshTokenAction, refreshTokenExpireDate } from './components/Login/actions/loginActions';
import { displayModalAction, closeModalAction } from './components/Generic/actions/genericActions';
import Countdown from 'react-countdown';
import { getTranslate } from 'react-localize-redux';

class IdleTimer extends Component {
	constructor(props) {
		super(props);

		this.idleTimer = null;
		this.intervalTimeLeft = null;

		this.state = {
			prevent_refresh: false,
		};
	}

	componentDidMount() {
		if (this.props.login.login && this.props.idle_timer) {
			setTimeout(() => {
				this.idleTimer.start();
				this.handleRefreshToken();
			}, 1000);
		}

		this.intervalTimeLeft = setInterval(() => {
			this.handleRefreshToken();
		}, (this.props.grace_timer / 4));
	}

	handleRefreshToken = () => {
		if (
			!this.state.prevent_refresh &&
			this.props.login.login &&
			this.idleTimer &&
			this.idleTimer.isLeader() &&
			new Date(this.props.login.token_expires_at) - new Date(new Date().toLocaleString('en-US', { timeZone: this.props.timezone })) <
				this.props.grace_timer
		) {
			this.handlePreventRefresh(true);
			this.props.refreshTokenAction((value) => {
				this.handlePreventRefresh(value);
				this.idleTimer.message({ data: 'on video conference', actions: ['refresh_token_expires_at'] });
			});
		}
	};

	handlePreventRefresh = (value) => {
		this.setState({
			prevent_refresh: value,
		});
	};

	componentWillUnmount() {
		clearInterval(this.intervalTimeLeft);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.login.login && this.props.login.login) {
			this.idleTimer.start();
			this.idleTimer.message({ data: 'logged on another tab', actions: ['login'] });
		} else if (prevProps.login.login && !this.props.login.login) {
			this.idleTimer.pause();
			this.idleTimer.message({ data: 'logged out on another tab', actions: ['force_logout'] });
		}
	}

	onMessage = (message) => {
		if (message.actions.includes('force_logout')) {
			this.props.logoutAction(true, this.props.login.type !== 'meeting');
		}
		if (message.actions.includes('logout')) {
			this.props.logoutAction(true, this.props.login.type !== 'meeting', this.props.translate('inactivity_logout_info_message'));
		}
		if (message.actions.includes('close_modal')) {
			this.props.closeModalAction();
		}
		if (message.actions.includes('start')) {
			this.idleTimer.start();
		}
		if (message.actions.includes('refresh_token_expires_at')) {
			setTimeout(() => {
				this.props.refreshTokenExpireDate();
			}, 1000);
		}
		if (!this.props.login.login && message.actions.includes('login')) {
			window.location.reload(false);
		}
	};

	onPrompt = () => {
		if (this.props.publisher.session) {
			this.idleTimer.message({ data: 'on video conference', actions: ['start', 'close_modal'] }, true);
		} else {
			// Fire a Modal Prompt
			this.props.displayModalAction({
				id: 'inactivity_modal',
				title: this.props.translate('inactivity_check_title'),
				description: (
					<React.Fragment>
						{this.props.translate('inactivity_check_description', {
							counter: (
								<Countdown
									renderer={({ hours, minutes, seconds, completed }) => (
										<span>
											{hours !== 0 ? hours + ':' : ''}
											{minutes}:{seconds}
										</span>
									)}
									daysInHours={true}
									date={Date.now() + this.props.grace_timer}
								/>
							),
						})}
					</React.Fragment>
				),
				button_no: this.props.translate('generic_button_logout'),
				button_yes: this.props.translate('generic_yes'),
				button_no_id: 'modal_idle_logout_button',
				action_no_button: () => {
					this.idleTimer.message({ data: 'log out', actions: ['force_logout'] }, true);
				},
				action: () => {
					this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'refresh_token_expires_at'] });
				},
				action_on_close: () => {
					this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'refresh_token_expires_at'] });
				},
			});
		}
	};

	onIdle = () => {
		this.idleTimer.message({ data: 'log out', actions: ['logout'] }, true);
		this.props.closeModalAction();
	};

	onActive = (event) => {};

	onAction = (event) => {
		if (this.idleTimer.isIdle()) {
			this.props.closeModalAction();
			this.idleTimer.message({ data: 'log out', actions: ['logout'] }, true);
		} else if (
			this.props.login.login &&
			this.idleTimer.isPrompted() &&
			event.target.id !== 'modal_idle_logout_button' &&
			event.target.parentElement.id !== 'modal_idle_logout_button'
		) {
			this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'start'] }, true);
		}
	};

	render() {
		return (
			<IdleTimerProvider
				ref={(ref) => {
					this.idleTimer = ref;
				}}
				timeout={this.props.idle_timer}
				promptTimeout={this.props.grace_timer}
				events={[
					'keydown',
					'wheel',
					'DOMMouseScroll',
					'mousewheel',
					'mousedown',
					'touchstart',
					'touchmove',
					'MSPointerDown',
					'MSPointerMove',
				]}
				onPrompt={this.onPrompt}
				onIdle={this.onIdle}
				onAction={this.onAction}
				onActive={this.onActive}
				onMessage={this.onMessage}
				startManually={true}
				crossTab={true}
				stopOnIdle={true}
				startOnMount={false}
				syncTimers={200}
				leaderElection={true}
			>
				{this.props.children}
			</IdleTimerProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	timezone: state.users.whoami.timezone,
	publisher: state.publisher,
	login: state.login,
	idle_timer: typeof state.settings.auth !== 'undefined' ? state.settings.auth.idle_timer : false,
	grace_timer: typeof state.settings.auth !== 'undefined' ? state.settings.auth.grace_timer : false,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, { logoutAction, refreshTokenAction, displayModalAction, closeModalAction, refreshTokenExpireDate })(
	IdleTimer
);
