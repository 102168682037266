import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Form } from '../Generic';
import { Typography } from '@material-ui/core';
import { resendTokenAction } from '../Users/actions/usersActions';
import { handleLoginConfirmed, sendVerifyTokenAction } from '../Login/actions/loginActions';
import { formValueSelector } from 'redux-form';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

class EmailConfirm extends Component {
	constructor(props) {
		super(props);
	}

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'emailcode',
			title: '',
			subtitle: '',
			helperText: '',
			fields: [
				{
					id: 'email',
					name: 'email',
					label: translate('user_external_field_email'),
					type: 'input',
					initialvalue: this.props.login.email_confirm,
					validate: {
						email: true,
						required: true,
					},
				},
				{
					id: 'token',
					name: 'token',
					label: translate('user_change_password_write_validate_code'),
					type: 'input',
					validate: {
						required: true,
					},
					initialvalue: '',
				},
			],
			buttons: [
				{
					id: 'email_resend_confirm',
					label: translate('email_resend_confirm'),
					fullWidth: true,
					color: 'primary',
					exclude_fields_validation: ['token'],
					submit: true,
					action: () => this.props.resendTokenAction('emailcode'),
				},
				{
					id: 'email_send_confirm',
					label: translate('email_send_confirm'),
					fullWidth: true,
					color: 'primary',
					action: () => this.props.sendVerifyTokenAction('emailcode'),
				},
				{
					id: 'email_back_button',
					label: translate('generic_button_back'),
					variant: 'text',
					color: 'primary',
					submit: false,
					fullWidth: false,
					startIcon: <ArrowBackIcon />,
					action: () => this.props.handleLoginConfirmed(true),
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<div>
				<Typography align="center" variant="subtitle2" component="p">
					{translate('login_confirmed_info')}
				</Typography>
				<Form form={configuration.structure.form} formData={configuration.structure} initialValues={configuration.initialValues} />
			</div>
		);
	}
}

const selector = formValueSelector('login');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	user: state.users.whoami,
	login: state.login,
	login_email: selector(state, 'login_email'),
});

export default connect(mapStateToProps, { resendTokenAction, sendVerifyTokenAction, handleLoginConfirmed })(EmailConfirm);
