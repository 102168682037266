import { FETCH_QUESTIONNAIRES_STATISTICS, FETCH_QUESTION_GROUPS } from '../actions/types';

const initialState = {
	questionnaires: [],
	question_groups: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_QUESTIONNAIRES_STATISTICS:
			const newArray = [...state.questionnaires];

			const index = state.questionnaires.findIndex((e) => e.id === action.payload.id);

			if (index === -1) {
				newArray.push(action.payload);
			} else {
				newArray[index] = action.payload;
			}

			return {
				...state,
				questionnaires: newArray,
			};
		case FETCH_QUESTION_GROUPS:
			const newQuestionsArray = [...state.question_groups];

			const indexQuestions = state.question_groups.findIndex((e) => e.id === action.payload.id);

			if (indexQuestions === -1) {
				newQuestionsArray.push(action.payload);
			} else {
				newQuestionsArray[indexQuestions] = action.payload;
			}

			return {
				...state,
				question_groups: newQuestionsArray,
			};
		default:
			return state;
	}
}
