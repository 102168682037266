import {
	SET_CHANNEL,
	SET_CHANNEL_HISTORY,
	SEND_CHANNEL_MESSAGE,
	FILTER_CHANNEL_MESSAGES,
	CLEAR_CHANNEL,
	SET_CHANNEL_PARTICIPANTS,
	SET_CHANNEL_NEW_MESSAGE,
	SET_READ_NOTIFICATIONS
} from '../actions/types';

export const setChannelAction = (channel_name, channel, messages, connection_date) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL,
		payload: {
			channel_name,
			data: { channel, messages, connection_date },
		},
	});
};

export const setChannelHistoryAction = (channel_name, messages) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_HISTORY,
		payload: {
			channel_name,
			data: { messages },
		},
	});
};

export const sendChannelMessageAction =
	(channel_name, message, pushMessageToTop = false) =>
	(dispatch) => {
		dispatch({
			type: SEND_CHANNEL_MESSAGE,
			payload: {
				channel_name,
				message,
				pushMessageToTop,
			},
		});
	};

export const filterChannelMessagesAction = (channel_name, messages_to_remove) => (dispatch) => {
	dispatch({
		type: FILTER_CHANNEL_MESSAGES,
		payload: { channel_name, messages_to_remove },
	});
};

export const setChannelParticipantsAction =
	(channel_name, participants = []) =>
	(dispatch) => {
		console.log(participants)
		dispatch({
			type: SET_CHANNEL_PARTICIPANTS,
			payload: {
				channel_name,
				participants,
			},
		});
	};

export const setChannelNewMessagesAction =
	(channel_name, mentioned = false, reset = false) =>
	(dispatch) => {
		dispatch({
			type: SET_CHANNEL_NEW_MESSAGE,
			payload: {
				channel_name,
				reset,
				mentioned
			},
		});
	};

export const clearChannelAction = (channel_name) => (dispatch) => {
	dispatch({
		type: CLEAR_CHANNEL,
		payload: { channel_name },
	});
};

export const setReadTicketNotificationsAction = (channel_name, origin_type, origin_id, ref_type, ref_id) => (dispatch) => { 
	dispatch({
		type: SET_READ_NOTIFICATIONS,
		payload: { channel_name, origin_type: origin_type , origin_id: origin_id, ref_type: ref_type, ref_id: ref_id},
	});
};
