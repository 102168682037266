import {
	VIDEO_TOGGLE_SUB,
	AUDIO_TOGGLE_SUB,
	ERROR_MESSAGE_SUB,
	CLOSE_VIDEO_SESSION
} from '../actions/types';

const initialState = {
	error: null,
	video: true,
	audio: true
};

export default function(state = initialState, action) {
	switch (action.type) {
		case VIDEO_TOGGLE_SUB:
			return {
				...state,
				video: action.payload
			};
		case AUDIO_TOGGLE_SUB:
			return {
				...state,
				audio: action.payload
			};
		case ERROR_MESSAGE_SUB:
			return {
				...state,
				error: action.payload
			};
		case CLOSE_VIDEO_SESSION:
			return {
				...initialState
			};
		default:
			return state;
	}
}
