import axios from 'axios';
import { store } from '../store.js';
import { setActiveLanguage, addTranslationForLanguage, initialize } from 'react-localize-redux';
import { handleSplashScreenAction } from '../components/Generic/actions/genericActions';
import { apiRequest } from '../helpers/functions/request';
import { renderToStaticMarkup } from 'react-dom/server';

export const fetchTranslationsAction = (code) => async (dispatch) => {
	const state = store.getState();
	
	return await axios
		.get(state.config.apihost + `/translations`, {
			params: { system_requesting: 'user-app', code: code, system: 'frontoffice' },
		})
		.then((res) => {
			setTimeout(() => {
				dispatch(addTranslationForLanguage(res.data, code));
				dispatch(setActiveLanguage(code));
				document.getElementsByTagName('html')[0].setAttribute('lang', code.split('-')[0]);
			}, 5000);
		})
		.catch((error) => {
			console.log(error.message);
		});
};

const onMissingTranslation = ({ translationId, languageCode }) => {
	return `${translationId}`;
};

export const initializeTranslations = (settings) => async (dispatch) => {
	const whoami = store.getState().users.whoami;

	const languages =
		whoami && whoami.scopes.includes('translations.toggle')
			? settings.site.available_languages.concat({ code: 'raw', label: 'raw' })
			: settings.site.available_languages;

	var codes = [];

	if (typeof settings.site !== 'undefined' && settings.site.available_languages) {
		var codes = settings.site.available_languages.reduce((array, object) => array.concat(object.code), []);
	}

	await dispatch(
		initialize({
			languages: languages,
			options: {
				onMissingTranslation,
				renderToStaticMarkup,
				renderInnerHtml: true,
				defaultLanguage:
					settings.params && settings.params !== null && settings.params.language && codes.includes(settings.params.language)
						? settings.params.language
						: whoami && whoami.language.length > 0
						? whoami.language
						: settings.site.default_language,
			},
		})
	);

	return true;
};

export const loadTranslations = (language) => async (dispatch) => {
	const config = store.getState().config;

	const response = await apiRequest({
		method: 'get',
		url: config.apihost + `/translations`,
		params: { system_requesting: 'user-app', code: language, system: 'frontoffice' },
		alert: 'alert_login',
	});

	dispatch(addTranslationForLanguage(response.data, language));
	
	return dispatch(setActiveLanguage(language));
};
