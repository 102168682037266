import {
	FETCH_ACTIVITIES,
	FETCH_ACTIVITY,
	POST_ACTIVITY,
	DELETE_ACTIVITY,
	FETCH_ACTIVITY_EVENTS,
	UPDATE_ACTIVITY_EVENTS,
	FETCH_ACTIVITY_ATTENDEES,
	REMOVE_ACTIVITY_ATTENDEE,
	REMOVE_ACTIVITY_EVENT,
	INVITATION_ACTIVITY,
	CLEAR_ACTIVITY,
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

import { TOGGLE_SESSION } from '../../VideoConference/actions/types';

const initialState = {
	fields: [],
	data: [],
	total_count: null,
	activity: {
		events: [],
		attendees: [],
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_ACTIVITIES:
			return {
				...state,
				fields: action.payload.columns,
				data: action.payload.data,
				total_count: action.payload.total_count,
			};
		case FETCH_ACTIVITY:
			return {
				...state,
				activity: { ...action.payload },
			};
		case POST_ACTIVITY:
			return {
				...state,
				activity: { ...action.payload, ...state.activity },
			};
		case DELETE_ACTIVITY:
			return {
				...state,
				activity: initialState.activity,
			};
		case FETCH_ACTIVITY_EVENTS:
			return {
				...state,
				activity: { ...state.activity, events: [...state.activity.events, action.payload] },
			};
		case UPDATE_ACTIVITY_EVENTS:
			const index = state.activity.events.findIndex((event) => event.id == action.payload.id);
			const newArray = [...state.activity.events];
			newArray[index] = action.payload;

			return {
				...state,
				activity: { ...state.activity, events: newArray },
			};
		case FETCH_ACTIVITY_ATTENDEES:
			return {
				...state,
				activity: { ...state.activity, attendees: [...state.activity.attendees, action.payload] },
			};
		case REMOVE_ACTIVITY_ATTENDEE:
			return {
				...state,
				activity: { ...state.activity, attendees: state.activity.attendees.filter((attendee) => attendee.id != action.payload.id) },
			};
		case REMOVE_ACTIVITY_EVENT:
			return {
				...state,
				activity: { ...state.activity, events: state.activity.events.filter((event) => event.id != action.payload.id) },
			};
		case INVITATION_ACTIVITY:
			return {
				...state,
				activity: {
					...state.activity, attendees: action.payload.attendees, },
			};
		case CLEAR_ACTIVITY:
			return {
				...state,
				activity: initialState.activity,
			};
		case TOGGLE_SESSION:
			return {
				...state,
				activity: { ...state.activity, scopes: ['activity.start_video_session'] },
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
