import { FETCH_POA_LIST } from '../actions/types';

const initialState = {
	data: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_POA_LIST:
			return {
				...state,
				data: action.payload,
			};
		default:
			return state;
	}
}
