export const FETCH_QUESTIONNAIRES = 'FETCH_QUESTIONNAIRES';
export const FETCH_QUESTIONNAIRE = 'FETCH_QUESTIONNAIRE';
export const PATCH_QUESTIONNAIRE = 'PATCH_QUESTIONNAIRE';
export const TRIGGER_QUESTION = 'TRIGGER_QUESTION';
export const ANSWER_QUESTION = 'ANSWER_QUESTION';
export const PROGRESS_QUESTIONNAIRE = 'PROGRESS_QUESTIONNAIRE';
export const TRIGGER_CHILDREN = 'TRIGGER_CHILDREN';
export const FETCH_TICKET_QUESTIONNAIRE = 'FETCH_TICKET_QUESTIONNAIRE';
export const HANDLE_QUESTIONNAIRE_ERROR = 'HANDLE_QUESTIONNAIRE_ERROR';

