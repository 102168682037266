/** Orig Types */
const TICKETS = 'App\\Models\\Tickets';
const MEETINGS  = 'App\\Models\\Meetings';
const USERS  = 'App\\Models\\Users';

/** Reference Types */
const TICKETS_TICKETS = 'App\\Models\\Tickets';
const TICKETS_FOLLOWUP = 'App\\Models\\Tickets';
const TICKETS_APPOINTMENTS = 'App\\Models\\Tickets\\Appointments';
const TICKETS_MEETINGS = 'App\\Models\\Meetings';
const TICKETS_FILES = 'App\\Models\\Tickets\\Files';
const TICKETS_QUESTIONNAIRES = 'App\\Models\\Tickets\\Questionnaires';
const TICKETS_ACCESS = 'App\\Models\\Tickets\\Access';
const CHAT_MESSAGES  = 'App\\Models\\Messages';
const TICKETS_QFILES = 'App\\Models\\Tickets\\Questionnaires\\Files';
const TICKETS_PRESCRIPTIONS = 'App\\Models\\Tickets\\Prescriptions';


export default {
	ORIG: {
		TICKETS,
		MEETINGS,
		USERS,
	},
	REF: {
		TICKETS_TICKETS,
		TICKETS_FOLLOWUP,
		TICKETS_APPOINTMENTS,
		TICKETS_MEETINGS,
		TICKETS_FILES,
		TICKETS_QUESTIONNAIRES,
		TICKETS_ACCESS,
		CHAT_MESSAGES,
		TICKETS_QFILES,
		TICKETS_PRESCRIPTIONS,
	},
};