export { default as RenderTextField } from './RenderTextField';
export { default as RenderRadioGroup } from './RenderRadioGroup';
export { default as RenderDatePicker } from './RenderDatePicker';
export { default as RenderTimePicker } from './RenderTimePicker';
export { default as RenderDateTimePicker } from './RenderDateTimePicker';
export { default as RenderSlider } from './RenderSlider';
export { default as RenderImageUpload } from './RenderImageUpload';
export { default as RenderAutocomplete } from './RenderAutocomplete';
export { default as RenderMultiAutocomplete } from './RenderMultiAutocomplete';
export { default as RenderLink } from './RenderLink';
export { default as RenderCheckboxGroup } from './RenderCheckboxGroup';
export { default as RenderSingleCheckbox } from './RenderSingleCheckbox';
export { default as RenderInputAdornment } from './RenderInputAdornment';
export { default as RenderWysiwyg } from './RenderWysiwyg';
export { default as RenderFileField } from './RenderFileField';
export { default as RenderTreeSelect } from './RenderTreeSelect';
export { default as RenderTextFieldMultiple } from './RenderTextFieldMultiple';

export { default as FunctionMultiAutocomplete } from './FunctionMultiAutocomplete';

const INPUT = 'input';
const PASSWORD = 'password';
const NUMBER = 'number';
const TIME = 'time';
const HIDDEN = 'hidden';
const RADIOBUTTON = 'radiobutton';
const CHECKBOX = 'checkbox';
const SINGLE_CHECKBOX = 'single_checkbox';
const SELECT = 'select';
const SELECT_MULTIPLE_CHECKBOX = 'select_multiple_checkbox';
const SELECT_GROUP = 'select_group';
const SELECT_TREE = 'select_tree';
const AUTOCOMPLETE_TEXTFIELD = 'autocomplete_textfield';
const MULTI_AUTOCOMPLETE_TEXTFIELD = 'multi_autocomplete_textfield';
const TEXTAREA = 'textarea';
const DATEPICKER = 'datepicker';
const TIMEPICKER = 'timepicker';
const DATETIMEPICKER = 'datetimepicker';
const SLIDER = 'slider';
const DROPZONE = 'dropzone';
const WYSIWYG = 'wysiwyg';
const LINK = 'link';
const INPUT_ADORNMENT = 'input_adornment';
const EXTERNAL = 'external';
const NONE = 'none';

export const TYPES = {
	INPUT,
	PASSWORD,
	NUMBER,
	TIME,
	HIDDEN,
	RADIOBUTTON,
	CHECKBOX,
	SINGLE_CHECKBOX,
	SELECT,
	SELECT_MULTIPLE_CHECKBOX,
	SELECT_GROUP,
	SELECT_TREE,
	AUTOCOMPLETE_TEXTFIELD,
	MULTI_AUTOCOMPLETE_TEXTFIELD,
	TEXTAREA,
	DATEPICKER,
	TIMEPICKER,
	DATETIMEPICKER,
	SLIDER,
	DROPZONE,
	WYSIWYG,
	LINK,
	INPUT_ADORNMENT,
	EXTERNAL,
	NONE
};
