import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import { getTranslate } from 'react-localize-redux';
import { NavBar, Footer, MessageToast } from './';
import { Container, Button } from '@material-ui/core';
import PrivateRoute from '../../helpers/PrivateRoute';
import { Loading, Modal, NotificationsModal } from '../Generic';
import Gallery from 'react-grid-gallery';
import { closeLightboxAction, disableLightboxZoomButtonAction, setMenuAction } from '../Generic/actions/genericActions';
import { postScreenShotAction } from '../Tickets/actions/ticketsActions';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ZoomIn as ZoomInIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorBoundary from './ErrorBoundary';
import Progress from './Progress';
import BlockUI from './BlockUI';

import SocketProvider from '../Chat/SocketProvider';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import MESSAGES from '../../helpers/messages';

class DefaultLayout extends Component {
	addZoomFeature = () => {
		this.props.disableLightboxZoomButtonAction();

		var el = document.querySelector('[class*=figure_]');
		var parent = document.querySelector('.generic-image-lightbox-zoom .react-transform-component');
		var child = document.querySelector('.generic-image-lightbox-zoom .react-transform-element');

		var newWrapper = parent.cloneNode(true);
		document.getElementsByClassName('generic-image-lightbox-zoom')[0].appendChild(newWrapper);

		if (!parent) {
			return false;
		}

		el.parentNode.insertBefore(child, el);

		child.appendChild(el);

		child.parentNode.insertBefore(parent, child);

		parent.appendChild(child);
	};

	onClickImage = () => {
		return false;
	};

	componentDidUpdate() {
		if (!this.props.lightbox.open) {
			Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
		}
	}

  handleFilterMessages = (data) => {
		return data.type === 'notification' && data.link_type !== 'read' && (this.props.user.scopes.includes('lists.cross_client') || data.ticket_client_id == this.props.user.current_client);   
	};


	render() {
		if (this.props.menu) {
			this.props.setMenuAction(this.props.menu);
		}
		
		const { dispatch, component: Component, path, extraProps, ...rest } = this.props;
		const { translate } = this.props;
		
		return rest.isLoggedIn ? (
      <SocketProvider
				channel_name={NOTIFICATIONS}
				channel={typeof rest.channel.name !== 'undefined' ? rest.channel.name : ''}
				handleFilterMessages={this.handleFilterMessages}
        skip_history={true}
			>
			<div className={"generic-defaultlayout-main " + (this.props.backgroundColorClass ? this.props.backgroundColorClass : '')}>
				{!rest.disableNavigation && <NavBar></NavBar>}
				{rest.loading ? (
					<Loading />
				) : (
					<React.Fragment>
						<Container
							component="main"
							maxWidth={this.props.fullsizeContainer ? false : 'lg'}
							{...(!this.props.disableMargins && { className: 'generic-defaultlayout-content' })}
							disableGutters={this.props.disableGutters}
						>
							<ErrorBoundary>
								<PrivateRoute {...rest} exact path={path} extraProps={extraProps} component={Component} />
							</ErrorBoundary>
						</Container>
					</React.Fragment>
				)}
				<BlockUI blocking={true} />
				<MessageToast />
				<Modal />
				<NotificationsModal />
				<Progress />
				{rest.lightbox.open ? (
					<React.Fragment>
						<div className={'generic-image-lightbox-zoom'}>
							<TransformWrapper>
								<TransformComponent></TransformComponent>
							</TransformWrapper>
						</div>
						<div style={{ display: 'none' }}>
							<Gallery
								isOpen={rest.lightbox.open}
								images={rest.lightbox.image}
								lightboxWillClose={rest.closeLightboxAction}
								enableImageSelection={false}
								customControls={[
									<Button
										disabled={rest.lightbox.disabledZoomButton}
										startIcon={<ZoomInIcon />}
										variant="contained"
										className={'lightbox-header-button-zoom'}
										onClick={this.addZoomFeature}
									>
										{translate('lightbox_enable_zoom')}
									</Button>,
									...(this.props.lightbox.screenshot !== null
										? [
												<Button
													startIcon={<SaveIcon />}
													variant="contained"
													className={'lightbox-header-button-save'}
													onClick={this.props.postScreenShotAction}
												>
													{translate('generic_button_save')}
												</Button>,
										  ]
										: []),
								]}
								onClickImage={this.onClickImage}
							/>
						</div>
					</React.Fragment>
				) : null}
				{!rest.disableNavigation && <Footer></Footer>}
			</div>
      </SocketProvider>
		) : (
			<Redirect to="/login" />
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.generic.loading,
	lightbox: state.generic.lightbox,
	isLoggedIn: state.login.login,
	translate: getTranslate(state.localize),
  channel : state.users.whoami.channel,
  user : state.users.whoami,
});

export default connect(mapStateToProps, { closeLightboxAction, disableLightboxZoomButtonAction, postScreenShotAction, setMenuAction })(DefaultLayout);
