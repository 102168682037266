const error_boundary = (theme) => {
	return {
		'.error-boundary-main-component': {
			textAlign: 'center',
		},
		'.error-boundary-main-icon': {
			width: theme.spacing(8),
			height: theme.spacing(8),
		},
	};
};

export default error_boundary;
