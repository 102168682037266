import { PROMPT_DIALOG_IS_DIRTY } from './types';

export const setPromptDialogIsDirty = ({
	is_dirty,
	path_allowed = null,
	path_not_allowed = null,
	back_label = 'generic_button_cancel',
	next_label = 'generic_button_continue',
	prompt_dialog_title = 'prompt_dialog_title',
	prompt_dialog_description = 'prompt_dialog_description',
	allow_resume = true,
	leave_action = null,
}) => (dispatch) => {
	console.log('setPrompt');
	dispatch({
		type: PROMPT_DIALOG_IS_DIRTY,
		payload: {
			is_dirty,
			path_allowed,
			path_not_allowed,
			back_label,
			next_label,
			prompt_dialog_title,
			prompt_dialog_description,
			allow_resume,
			leave_action,
		},
	});
};
