
import React from 'react';
import { Link } from '@material-ui/core';

function RenderLink({ input, meta, ...custom }) {
    return (
        <Link
			id={custom.inputdata.id}
			name={custom.inputdata.name}
			variant="body2"
			component="button"
			onClick={(e) => {
				custom.inputdata.options.action(e);
			}}
			{...custom}
			{...input}
			underline="none"
			className={
				input.value
					? 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth MuiLink-button generic-link-button'
					: 'hidden'
			}
		>
			{custom.inputdata.label}
		</Link>
    );
}

export default RenderLink;