const poa = (theme) => {
	return {
		'.poa-container': {
			backgroundColor: '#e6e6e6',
			borderRadius: theme.spacing(1),
			marginTop: theme.spacing(1),
			display: 'block',
		},
		'.poa-list-top': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.poa-list-middle': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.poa-list-bottom': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.poa-list-button-close': {
			float: 'right',
			right: theme.spacing(2),
			top: theme.spacing(2),
		},
		'.poa-meeting-content-back-botton': {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
		},
		'.poa-meeting-title': {
			marginTop: theme.spacing(2),
		},

		'.poa-list-active': {
			backgroundColor: theme.palette.success.light,
		},
		'.poa-list-pending': {
			backgroundColor: theme.palette.warning.light,
		},
		'.poa-list-revoked': {
			backgroundColor: theme.palette.error.light,
		},
		'.poa-list-unverified': {
			backgroundColor: theme.palette.common.white,
		},
		'.poa-list-inactive': {
			backgroundColor: theme.palette.grey[500],
		},

		'.poa-list-active-legend': {
			color: theme.palette.success.light,
		},
		'.poa-list-pending-legend': {
			color: theme.palette.warning.light,
		},
		'.poa-list-revoked-legend': {
			color: theme.palette.error.light,
		},
		'.poa-list-unverified-legend': {
			color: theme.palette.common.white,
		},
		'.poa-list-inactive-legend': {
			color: theme.palette.grey[500],
		},
		// Poa Status chip
		'.poa-chipstatus-unverified': {
			color: 'black',
			backgroundColor: theme.palette.common.white,
		},
		'.poa-chipstatus-pending': {
			color: 'white',
			backgroundColor: theme.palette.warning.light,
		},
		'.poa-chipstatus-active': {
			color: 'white',
			backgroundColor: theme.palette.success.light,
		},
		'.poa-chipstatus-revoked': {
			color: 'white',
			backgroundColor: theme.palette.error.light,
		},
		'.poa-chipstatus-inactive': {
			color: 'white',
			backgroundColor: theme.palette.grey[700],
		},
		'.poa-list-accordion': {
			backgroundColor: 'inherit',
		},
		'.poa-list-accordion-avatar': {
			width: theme.spacing(3),
			height: theme.spacing(3),
			marginRight: theme.spacing(2),
			backgroundColor: theme.palette.primary.main,
			fontSize: 'inherit'
		},
	};
};

export default poa;
