import React, { Component } from 'react';
import NavBarFunction from './NavBarFunction';
import { connect } from 'react-redux';
import { logoutAction } from '../Login/actions/loginActions';
import { fetchUnreadTicketsAction } from '../Tickets/actions/ticketsActions';
import { handleToggleTranslations } from '../../actions/settingsActions';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

class NavBar extends Component {

	componentDidMount() {
		if (this.props.client.logo.length > 0) {
			const src = this.props.config.apihost + '/auth/clients/' + this.props.client.id + '/files/' + this.props.client.logo[0].uuid;
			
			fetch(src)
				.then((res) => {
					return res.blob();
				})
				.then((blob) => {
					var urlCreator = window.URL || window.webkitURL;
					var imageUrl = urlCreator.createObjectURL(blob);

					var img = document.querySelector('#navbar-logo');
					if (img) {
						img.src = imageUrl;
					}
				});
		} else {
			var imgEle = document.querySelector('#navbar-logo');

			if (imgEle) {
				imgEle.src = typeof this.props.settings.site !== 'undefined' && this.props.settings.site.files.navbar_logo
					? this.props.config.apihost + '/configuration/files/' + this.props.settings.site.files.navbar_logo.uuid
					: PlaceholderIMG;
			}
		}
	}

	componentWillUnmount() {
		// clearInterval(this.notificationsTimer);
	}

	toggleTranslations = () => {
		this.props.handleToggleTranslations();
	};

	render() {
		return (
			<NavBarFunction
				logout={this.props.logoutAction}
				toggleTranslations={() => this.toggleTranslations()}
				navButtonActive={this.props.menuActive}
				settings={this.props.settings}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.users.whoami,
	menuActive: state.users.menu,
	config: state.config,
	client: state.settings.clients.find(client => client.id === state.login.client.id),
	settings: state.settings,
});

export default connect(mapStateToProps, { logoutAction, fetchUnreadTicketsAction, handleToggleTranslations })(NavBar);
