import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Typography, Menu, MenuItem, Divider } from '@material-ui/core';
import {
	ExitToApp as ExitToAppIcon,
	AccountCircle as AccountCircleIcon,
	List as ListIcon,
	Event as EventIcon,
	PersonAdd as PersonAddIcon,
	Add as AddIcon,
	Dashboard as DashboardIcon,
	Group as GroupIcon,
	LocalLibrary as LocalLibraryIcon,
} from '@material-ui/icons';

export function NavBarMenuMobile({ translate, ...props }) {
	return (
		<Menu
			anchorEl={props.mobileMoreAnchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			id={props.mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={props.isMobileMenuOpen}
			onClose={props.handleMobileMenuClose}
		>
			<MenuItem disabled={true}>
				<Typography>{props.username}</Typography>
			</MenuItem>
			<Divider />
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.dashboard ? (
				<MenuItem
					id="navbar_menu_dashboard"
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'dashboard' ? 'generic-navbar-menu-active' : null)}
					component={RouterLink}
					to="/dashboard"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
				>
					<IconButton aria-label="Current user profile" color="primary">
						<DashboardIcon />
					</IconButton>
					<Typography>{translate('navbar_button_dashboard')}</Typography>
				</MenuItem>
			) : null}
			{!props.is_temporary_user && props.scopes.includes('navigation.mypages') && (
				<MenuItem
					id="navbar_menu_mypages"
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mypages' ? 'generic-navbar-menu-active' : null)}
					component={RouterLink}
					to="/mypages"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
				>
					<IconButton aria-label="Current user profile" color="primary">
						<AccountCircleIcon />
					</IconButton>
					<Typography>{translate('navbar_button_mypages')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.my_cases') && (
				<MenuItem
					id="navbar_menu_mycases"
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mycases' ? 'generic-navbar-menu-active' : null)}
					component={RouterLink}
					to="/mycases"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Typography>{translate('navbar_button_mycases')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.waitingroom') && (
				<MenuItem
					id="navbar_menu_waitingroom"
					component={RouterLink}
					to="/waitingroom"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'waitingroom' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Typography>{translate('navbar_button_waitingroom')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.all_cases') && (
				<MenuItem
					id="navbar_menu_allcases"
					component={RouterLink}
					to="/allcases"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'allcases' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Typography>{translate('navbar_button_allcases')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('customer') && props.scopes.includes('tickets.new_case') && (
				<MenuItem
					id="navbar_menu_conditions"
					component={RouterLink}
					to="/conditions"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'conditions' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<AddIcon />
					</IconButton>
					<Typography>{translate('navbar_button_newcase')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('calendar') && (
				<MenuItem
					id="navbar_menu_mycalendar"
					component={RouterLink}
					to="/mycalendar"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mycalendar' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="calendar" color="primary">
						<EventIcon />
					</IconButton>
					<Typography>{translate('navbar_button_calendar')}</Typography>
				</MenuItem>
			)}

			{!props.is_temporary_user && props.scopes.includes('ticket.invite') && (
				<MenuItem
					id="navbar_menu_invite"
					component={RouterLink}
					to="/invite"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'invite' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="invite" color="primary">
						<PersonAddIcon />
					</IconButton>
					<Typography>{translate('navbar_button_ticket_invite')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.activities && (
				<MenuItem
					id="navbar_menu_activities"
					component={RouterLink}
					to="/activities"
					color="inherit"
					underline="none"
					onClick={() => {
						props.handleMobileMenuClose();
					}}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'activities' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="activity" color="primary">
						<GroupIcon />
					</IconButton>
					<Typography>{translate('navbar_button_activity')}</Typography>
				</MenuItem>
			)}
			{!props.is_temporary_user &&
				typeof props.settings.feature !== 'undefined' &&
				props.settings.feature.libraries &&
				props.scopes.includes('library') && (
					<MenuItem
						id="navbar_menu_library"
						component={RouterLink}
						to="/library"
						color="inherit"
						underline="none"
						onClick={() => {
							props.handleMobileMenuClose();
						}}
						className={'generic-navbar-menu-item ' + (props.navButtonActive === 'library' ? 'generic-navbar-menu-active' : null)}
					>
						<IconButton aria-label="activity" color="primary">
							<LocalLibraryIcon />
						</IconButton>
						<Typography>{translate('navbar_button_library')}</Typography>
					</MenuItem>
				)}
			<Divider />
			<MenuItem id="navbar_menu_logout" className="generic-navbar-menu-item" onClick={() => props.logout(true)}>
				<IconButton aria-label="Logout" aria-controls="primary-search-account-menu" aria-haspopup="true" color="primary">
					<ExitToAppIcon />
				</IconButton>
				<Typography>{translate('navbar_button_logout')}</Typography>
			</MenuItem>
		</Menu>
	);
}
