import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { loginNetIDAction, checkNetIdProgress } from './actions/loginActions';
import { Loading } from '../Generic';

class LoginSITHS extends Component {
	state = {
		ssn: '',
		i: 1,
		selectedLanguage: this.props.selectedLanguage
	};

	componentDidUpdate() {
		if (this.props.netid_progress) {
			setTimeout(() => {
				this.props.checkNetIdProgress(this.state.i);
			}, 1000);
		}
	}

	handleSSN = (e) => {
		this.setState({
			ssn: e.target.value
		});
	};

	render() {
		const { translate } = this.props

		return this.props.netid_progress ? (
			<div>
				<Loading size={50} insideComponent={true} />
				<Typography>{translate('login_siths_open_app')}</Typography>
			</div>
		) : (
			<form className="login-content-right-content-tab-form" noValidate>
                <Typography>{translate('login_siths_signin')}</Typography>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className="login-content-right-content-tab-form-submit"
					onClick={(e) => {
						e.preventDefault();
						this.props.loginNetIDAction(this.state);
					}}
				>
					{translate('generic_button_sign_in')}
				</Button>
				
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	netid_progress: state.login.netid_progress,
	netid_check_tries: state.login.netid_check_tries,
	translate: getTranslate(state.localize)
});

export default connect(
	mapStateToProps,
	{ loginNetIDAction, checkNetIdProgress }
)(LoginSITHS);
