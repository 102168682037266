import React from 'react';
import { Typography, Box } from '@material-ui/core';

function TabContainer(props) {
	const { children, value, index, ...other } = props;
	// fix box textAlign style
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<Box {...(props.parent && props.parent == 'meeting_logins' ? { style: { textAlign: 'center' } } : {})}>{children}</Box>
		</Typography>
	);
}
export default TabContainer;
