/**Prescription status */
const PENDING = 10;
const VALIDATED = 100;
const REFUSED = 50;

export default {
	STATUS: {
		PENDING,
    VALIDATED,
    REFUSED,
	}
};
