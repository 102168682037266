export const VIDEO_TOGGLE_STREAM_ID    = 'VIDEO_TOGGLE_STREAM_ID';
export const AUDIO_TOGGLE_PUB    = 'AUDIO_TOGGLE_PUBLISHER';
export const VIDEO_TOGGLE_PUB    = 'VIDEO_TOGGLE_PUBLISHER';
export const VIDEO_SOURCE_PUB    = 'VIDEO_SOURCE_PUBLISHER';
export const CAMERA_POSITION_PUB = 'CAMERA_POSITION_PUBLISHER';
export const ERROR_MESSAGE_PUB   = 'ERROR_MESSAGE_PUBLISHER';
export const AUDIO_TOGGLE_SUB    = 'AUDIO_TOGGLE_SUBSCRIBER';
export const VIDEO_TOGGLE_SUB    = 'VIDEO_TOGGLE_SUBSCRIBER';
export const ERROR_MESSAGE_SUB   = 'ERROR_MESSAGE_SUBSCRIBER';
export const TOGGLE_PRECALL_SESSION      = 'TOGGLE_PRECALL_SESSION';
export const TOGGLE_SESSION      = 'TOGGLE_SESSION';
export const CLOSE_VIDEO_SESSION = 'CLOSE_VIDEO_SESSION';
export const DEFAULT_SETTINGS = 'DEFAULT_SETTINGS';
export const TOGGLE_VIDEO_FILTER = 'TOGGLE_VIDEO_FILTER';
