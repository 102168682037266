const questionnaires = (theme) => {
	return {
		'.MuiGrid-container > span': {
			display: 'none'
		},

		'.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-8': {
			width: 'unset',
			margin: 'unset'
		},

		'.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4': {
			padding: 'unset'
		},

		'.questionnaires-buttonbar-main': {
			display: 'flex',
			justifyContent: 'center'
		},
		'.questionnaires-imageupload-dropzone-container': {
			minHeight: 'unset'
		},
		'.questionnaires-imageupload-dropzone-text': {
			fontSize: theme.typography.fontSize,
			fontFamily: theme.typography.fontFamily
		},
		'.questionnaires-question-main': {
			padding: theme.spacing(3),
			color: theme.palette.text.secondary,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2)
        },
		'.questionnaires-question-main-error': {
			border: '2px solid red'
        },
		'.questionnaires-question-main-error-icon': {
            color: 'red', 
            float: 'right', 
            cursor: 'unset'
        },
		'.questionnaires-question-main-error-text': {
            color: 'red', 
        },
        '.questionnaires-question-text': {
			fontWeight: 'bold'
		}
	};
};

export default questionnaires;
