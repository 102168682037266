const notifications = (theme) => {
	return {
		'.notifications-list-item-icon': {
			color: theme.palette.primary.main,
			minWidth: theme.spacing(4),
		},
		'.notification-bell-hidden': {
			display: 'none !important',
		},
		'.notification-table-read-icon': {
			color: theme.palette.grey[600],
		},
		'.notification-modal-close-icon': {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[600],
		},
    '.message-tab-badge': {
      right: '-8px'
    }
	};
};

export default notifications;
