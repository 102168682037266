import {
	SET_CHANNEL,
	SET_CHANNEL_HISTORY,
	SEND_CHANNEL_MESSAGE,
	FILTER_CHANNEL_MESSAGES,
	CLEAR_CHANNEL,
	SET_CHANNEL_PARTICIPANTS,
	SET_CHANNEL_NEW_MESSAGE,
	SET_READ_NOTIFICATIONS
} from '../actions/types';
import { WEB_SOCKET_CHANNELS } from '../../../helpers/webSocketChannels';

const initialState = WEB_SOCKET_CHANNELS.reduce((result, item, index, array) => {
	result[item] = {
		channel: '',
		messages: [],
		connection_date: null,
		participants: [],
    has_history: false,
		new_messages: {
			count: 0,
			mentioned: false,
		},
	};
	return result;
}, {});

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: { ...state[action.payload.channel_name], ...action.payload.data },
			};
		case SET_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: [...state[action.payload.channel_name].messages, ...action.payload.data.messages],
          has_history: true,
				},
			};
		case SEND_CHANNEL_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: action.payload.pushMessageToTop
						? [
								action.payload.message,
								...state[action.payload.channel_name].messages.filter((message) => message.id !== action.payload.message.id),
						  ]
						: [...state[action.payload.channel_name].messages, action.payload.message],
				},
			};
		case FILTER_CHANNEL_MESSAGES:
			// change this!!
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: state[action.payload.channel_name].messages.filter(
						(message) => !action.payload.messages_to_remove.some((message_to_remove) => message.id == message_to_remove.id)
					),
				},
			};
		case SET_READ_NOTIFICATIONS:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: state[action.payload.channel_name].messages.map((message) => {
						if (message.origin_type === action.payload.origin_type && message.origin_id === action.payload.origin_id) {
							if (!action.payload.ref_type){
								message.unread = '0';
								return message;
							}
							if (message.ref_type === action.payload.ref_type){
								if (!action.payload.ref_id || action.payload.ref_id == '0'){
									message.unread = '0';
									return message;
								}
								if (action.payload.ref_id && message.ref_id === action.payload.ref_id){
									message.unread = '0';
									return message;
								}
							}		
						}
						return message;
					}),
				},
			};
		case CLEAR_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: initialState[action.payload.channel_name],
			};
		case SET_CHANNEL_PARTICIPANTS:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					participants: action.payload.participants,
				},
			};
		case SET_CHANNEL_NEW_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					new_messages: action.payload.reset
						? initialState[action.payload.channel_name].new_messages
						: {
								count: state[action.payload.channel_name].new_messages.count + 1,
								...(action.payload.mentioned
									? { mentioned: action.payload.mentioned }
									: { mentioned: state[action.payload.channel_name].new_messages.mentioned }),
						  },
				},
			};
		default:
			return state;
	}
}
