import React from 'react';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, sv } from 'date-fns/locale';
const localeMap = {
	'en-US': enUS,
	'sv-SE': sv,
};

function RenderTimePicker({ input: { onChange, value }, meta: { invalid, touched, error, active }, ...custom }) {
	return (
		<MuiPickersUtilsProvider
			utils={DateFnsUtils}
			locale={Object.keys(localeMap).includes(custom.locale) ? localeMap[custom.locale] : localeMap['en-US']}
		>
			<TimePicker
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				format={'HH:mm'}
				label={custom.inputdata.label}
				fullWidth
				inputVariant="outlined"
				onChange={(date) => {
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(date);
					}
					onChange(date);
				}}
				value={value === '' ? null : value}
				ampm={false}
				disableFuture={custom.inputdata.disableFuture}
				disablePast={custom.inputdata.disablePast}
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				openTo={custom.inputdata.openTo}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default RenderTimePicker;
