import React from 'react';
import { FormHelperText } from '@material-ui/core';

import { FunctionMultiAutocomplete } from './';

function RenderMultiAutocomplete({ input, meta: { invalid, touched, error, active }, ...custom }) {
	return (
		<div>
			<FunctionMultiAutocomplete
				initialValue={custom.inputdata.initialvalue}
				options={custom.inputdata.options}
				customProps={custom}
				input={input}
			/>

			<FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{touched && !active && error}</FormHelperText>
		</div>
	);
}

export default RenderMultiAutocomplete;
