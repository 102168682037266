/**Country formats with hours */
const SWEH = 'yyyy-MM-dd HH:mm';
const GBRH = 'dd/MM/yyyy HH:mm';
const USAH = 'M/d/yyyy HH:mm';
const PRTH = 'dd-MM-yyyy HH:mm';
const SWAH = 'dd-MM-yyyy HH:mm';

/**Country formats without hours */
const SWE = 'yyyy-MM-dd';
const GBR = 'dd/MM/yyyy';
const USA = 'M/d/yyyy';
const PRT = 'dd-MM-yyyy';
const SWA = 'dd-MM-yyyy';

export default {
	FORMATSH: {
		SWEH,
		GBRH,
    USAH,
		PRTH,
		SWAH,
	},
  FORMATS: {
		SWE,
		GBR,
    USA,
		PRT,
		SWA,
	},
};
