import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Container, Typography, Link } from '@material-ui/core';
import PlaceholderIMG from '../../assets/images/placeholder.svg';

function Footer({ translate, ...props }) {
	return (
		<footer className="generic-footer">
			<Container
				maxWidth={props.settings.styling.always_display_general_brand ? 'xs' : 'sm'}
				style={{ display: props.settings.styling.always_display_general_brand ? 'flex' : '' }}
			>
				{props.settings.styling.always_display_general_brand && (
					<img
						src={
							typeof props.settings.site !== 'undefined' && props.settings.site.files.navbar_logo
								? props.config.apihost + '/configuration/files/' + props.settings.site.files.navbar_logo.uuid
								: PlaceholderIMG
						}
						height="20"
						alt="Alteri's logo"
					/>
				)}
				<Typography
					variant="body2"
					color="textSecondary"
					align="center"
					style={{ paddingLeft: props.settings.styling.always_display_general_brand ? '24px' : '' }}
				>
					{translate('footer_copyright', {
						footer_copyright_company: (
							<Link color="inherit" href="https://altericare.se" target="_blank">
								{translate('company_name')}
							</Link>
						),
					})}{' '}
					{new Date().getFullYear()}
				</Typography>
			</Container>
		</footer>
	);
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	settings: state.settings,
	config: state.config,
});

export default connect(mapStateToProps)(Footer);
