const snackbar = (theme) => {
	return {
		'.snackbar-variant-info': {
            backgroundColor: theme.palette.grey[300],
            // backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.black
        },
        '.snackbar-variant-info svg': {
            color: theme.palette.primary.main
        }
	};
};

export default snackbar;
