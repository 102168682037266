const activities = (theme) => {
	return {
		'.activity-events-container': {
			backgroundColor: '#e6e6e6',
			borderRadius: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		'.activity-locked-form-container': {
			opacity: '70%',
		},
		'.activity-events-list-top': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.activity-events-list-middle': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.activity-events-list-bottom': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		'.activity-events-list-button-close': {
			float: 'right',
			right: theme.spacing(2),
			top: theme.spacing(2),
		},
		'.activity-event-meeting-content-back-botton': {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
		},
		'.activity-chipstatus-join': {
			color: 'white',
			backgroundColor: '#00A99D',
		},
		'.activity-chipstatus-registered': {
			color: 'white',
			backgroundColor: '#378805',
		},
		'.activity-event-meeting-title': {
			marginTop: theme.spacing(2),
		},
        '.activity-no-event-row-background-color': {
			backgroundColor: theme.palette.grey[100],
		},
		'.activity-events-list-occurring-event': {
			backgroundColor: theme.palette.success.light
		},
		'.activity-events-list-next-event': {
			backgroundColor: theme.palette.warning.light
		},
		'.activity-events-list-occurring-event-legend': {
			color: theme.palette.success.light
		},
		'.activity-events-list-next-event-legend': {
			color: theme.palette.warning.light
		}
	};
};

export default activities;
