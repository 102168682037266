import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormControlLabel, FormLabel, FormHelperText, Checkbox, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export default class RenderCheckboxGroup extends Component {
	static propTypes = {
		options: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
			})
		).isRequired,
	};

	field = ({ input, meta, options, inputdata }) => {
		const { name, onChange } = input;
		const { touched, error, invalid, active } = meta;
		const inputValue = input.value;

		const checkboxes = options.map(({ label, value }, index) => {
			const handleChange = (event) => {
				const arr = [...inputValue];
				if (event.target.checked) {
					arr.push(value);
				} else {
					arr.splice(arr.indexOf(value), 1);
				}

				if (typeof this.props.onChangeValue !== 'undefined') {
					this.props.onChangeValue(arr);
				}

				return onChange(arr);
			};
			const checked = inputValue.includes(value);
			return (
				<FormControlLabel
					key={index}
					control={<Checkbox id={`${name}[${index}]`} name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange} />}
					label={label}
				/>
			);
		});

		return (
			<FormControl error={touched && invalid && !active}>
				<FormLabel component="legend">
					{inputdata.label}
					{inputdata.infoTooltip && (
						<Tooltip title={inputdata.infoTooltip} placement="right-end" color="secondary">
							<InfoIcon size="small" />
						</Tooltip>
					)}
				</FormLabel>
				<FormGroup>{checkboxes}</FormGroup>
				<FormHelperText>{touched && !active && error}</FormHelperText>
			</FormControl>
		);
	};

	render() {
		return <Field {...this.props} type="checkbox" component={this.field} validate={this.props.validate} />;
	}
}
