import React, { Component } from 'react';
import { withLocalize, addTranslation } from 'react-localize-redux';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { fetchTranslationsAction } from './actions/translationsActions';
import { fetchSettingsAction, addSettingsAction } from './actions/settingsActions';
import { handleLoadingAction } from './components/Generic/actions/genericActions';
import { setPromptDialogIsDirty } from './components/Generic/actions/promptActions';
import { withRouter } from 'react-router-dom';
import ReactRouterPause from '@allpro/react-router-pause';
import PromptDialog from './components/Generic/PromptDialog';
import FullsizeLoading from './components/Generic/FullsizeLoading';
import CssBaseline from '@material-ui/core/CssBaseline';
import { logoutAction } from './components/Login/actions/loginActions';
import { initializeAction } from './actions/genericActions';
import { displayModalAction, closeModalAction } from './components/Generic/actions/genericActions';
// import { withIdleTimer } from 'react-idle-timer';
import { getTranslate } from 'react-localize-redux';

// Our theme provider
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './assets/Theme';
import IdleTimer from './IdleTimer';

// import IdleTimer from "react-idle-timer";

class Main extends Component {
	constructor(props) {
		super(props);

		if (props.location.search.length !== 0) {
			let search = props.location.search.substring(1);

			search = search.split('&');

			let params = {};

			search.forEach((param) => {
				param = param.split('=');
				Object.assign(params, { [param[0]]: param[1] });
			});

			if (
				params.conditions ||
				params.language ||
				params.tickets ||
				params.activities ||
				params.conditions_id ||
				params.practitioners_id ||
				params.starts_at ||
				params.ends_at ||
        params.start
			) {
				let aux = { ...params };
				Object.keys(aux).forEach((key) => {
					aux[key] = decodeURI(aux[key]);
				});

				props.addSettingsAction(aux);
			} else {
				props.replace(props.location.pathname);
			}
		}

		this.state = { showDialog: false, showDialogLogoutPrompt: false };
		this.navigation = null;

		// this.idleTimer = null;
		// this.onPrompt = this.onPrompt.bind(this);
		// this.onIdle = this.onIdle.bind(this);
		// this.onAction = this.onAction.bind(this);
		// this.onActive = this.onActive.bind(this);
		// this.onMessage = this.onMessage.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		this.props.initializeAction(
			true,
			!this.props.login.login || this.props.location.pathname === '/' || this.props.location.pathname === '/login'
		);

		if (
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			this.setupMatomoConnection();
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.props.lightbox.open) {
			Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
		}

		if (this.state.showDialog && !this.props.login.login) {
			this.closeDialog();
		}

		if (
			this.props.login.login &&
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			var _mtm = (window._mtm = window._mtm || []);
			_mtm.push({ role: this.props.user.role });
		}

		// if (!this.props.login.login) {
		// 	this.idleTimer.reset();
		// } else if (this.props.login.login && !this.props.modal.open) {
		// 	this.idleTimer.start();
		// }
	}

	// INFO set up matomo connection with the matomo host from confg and container from settings
	setupMatomoConnection = () => {
		var _paq = (window._paq = window._paq || []);
		_paq.push(['trackPageView']);
		_paq.push(['enableLinkTracking']);
		let config = this.props.config; // cache the this
		let container = this.props.settings.matomo.container; // cache the this
		(function () {
			var u = config.matomohost;
			_paq.push(['setTrackerUrl', u + 'matomo.php']);
			_paq.push(['setSiteId', '2']);
			var d = document,
				g = d.createElement('script'),
				s = d.getElementsByTagName('script')[0];
			g.type = 'text/javascript';
			g.async = true;
			g.src = u + 'matomo.js';
			s.parentNode.insertBefore(g, s);
		})();

		var _mtm = (window._mtm = window._mtm || []);
		_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
		var d = document,
			g = d.createElement('script'),
			s = d.getElementsByTagName('script')[0];
		g.type = 'text/javascript';
		g.async = true;
		g.src = config.matomohost + 'js/container_' + container + '.js';
		s.parentNode.insertBefore(g, s);
	};

	handleNavigationAttempt = (navigation, location, action) => {
		this.navigation = navigation;
    console.log('navigation: ', navigation)
    console.log('location: ', location)
    console.log('action: ', action)
		// Implement path_not_allowed behavior:
		// if (this.props.path_not_allowed !== null && this.props.path_not_allowed.some(v => location.pathname.includes(v)))

		if (this.props.is_dirty) {
			if (action === 'POP') {
				this.setState({ showDialog: true });
				return null;
			} else if (this.props.path_allowed !== null && !this.props.path_allowed.some((v) => location.pathname.includes(v))) {
				this.setState({ showDialog: true });
				return null;
			} else {
				return true;
			}
		} else if (this.props.login.login) {
      const regex = new RegExp('login');

			if (action === 'POP' && regex.test(location.pathname)) {
        console.log('is /login location pathname condition')
				this.setState({ showDialogLogoutPrompt: true });
				return null;
			} else {
				return true;
			}
		}

		// Return null to 'pause' and save the route so can 'resume'
	};

	closeDialog = () => {
		this.setState({ showDialog: false });
	};

	closeLogoutDialog = () => {
		this.setState({ showDialogLogoutPrompt: false });
	};

	handleStay = () => {
		this.closeDialog();
		this.navigation.cancel();
	};

	handleStayLogout = () => {
		this.closeLogoutDialog();
		this.navigation.cancel();
	};

	handleLeave = () => {
		this.closeDialog();

		this.navigation.resume();
		this.props.setPromptDialogIsDirty({ is_dirty: false });

		if (this.props.leave_action !== null && typeof this.props.leave_action == 'function') {
			this.props.leave_action();
		}
	};

	handleLeaveLogout = () => {
		this.closeLogoutDialog();

		this.navigation.resume();
		this.props.logoutAction(true, false);
	};

	render() {
		// const Routes = React.lazy(() => import('./Routes'));

		return (
			<ThemeProvider theme={theme(this.props.settings, this.props.client)}>
				<CssBaseline />
				<IdleTimer>
					<ReactRouterPause handler={this.handleNavigationAttempt} when={this.props.is_dirty || this.props.login.login} />
					{/* <ReactRouterPause handler={this.handleNavigationLogoutAttempt} when={this.props.login.login} /> */}
					<PromptDialog
						open={this.state.showDialog}
						cancel={this.handleStay}
						resume={this.handleLeave}
						back_label={this.props.back_label}
						next_label={this.props.next_label}
					/>
					<PromptDialog
						open={this.state.showDialogLogoutPrompt}
						cancel={this.handleStayLogout}
						resume={this.handleLeaveLogout}
						back_label={'generic_button_stay'}
						next_label={'generic_button_logout'}
						prompt_dialog_title={'prompt_logout_dialog_title'}
						prompt_dialog_description={'prompt_logout_dialog_description'}
					/>
					<SnackbarProvider maxSnack={10} classes={{ variantInfo: 'snackbar-variant-info' }}>
						{this.props.splash_screen ? <FullsizeLoading /> : <Routes settings={this.props.settings} config={this.props.config} />}
					</SnackbarProvider>
				</IdleTimer>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.users.whoami,
	publisher: state.publisher,
	is_dirty: state.prompt.is_dirty,
	path_allowed: state.prompt.path_allowed,
	path_not_allowed: state.prompt.path_not_allowed,
	leave_action: state.prompt.leave_action,
	back_label: state.prompt.back_label,
	next_label: state.prompt.next_label,
	lightbox: state.generic.lightbox,
	modal: state.generic.modal,
	splash_screen: state.generic.splash_screen,
	login: state.login,
	settings: state.settings,
	config: state.config,
	client: state.login.client,
	idle_timer: typeof state.settings.auth !== 'undefined' ? state.settings.auth.idle_timer : false,
	grace_timer: typeof state.settings.auth !== 'undefined' ? state.settings.auth.grace_timer : false,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, {
	fetchTranslationsAction,
	addTranslation,
	handleLoadingAction,
	setPromptDialogIsDirty,
	fetchSettingsAction,
	addSettingsAction,
	replace,
	logoutAction,
	initializeAction,
	displayModalAction,
	closeModalAction,
})(withLocalize(withRouter(Main)));
