import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { getLanguages } from 'react-localize-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Paper, Box, Grid } from '@material-ui/core';
import LoginPanel from './LoginPanel';
import { fetchTranslationsAction } from '../../actions/translationsActions';
import { MessageToastLogin } from '../Generic';
import { handleSelectClientAction } from './actions/loginActions';
import { isMobileSize } from '../../helpers/functions/functions';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recovery: false,
			recovery_token : null,
		};
	}

	componentDidMount() {
		if (this.props.match.path == '/backoffice' && this.props.platform == 'backoffice') {
			this.props.handleSelectClientAction({ id: 0 }, 'backoffice');
		} else if (this.props.platform == 'frontoffice') {
			if (!this.props.login) {
				if (this.props.platform == 'frontoffice') {
					if (this.props.match.params.client) {
						var selectedClient = this.props.settings.clients.find((client) => {
							return client.short_name === this.props.match.params.client;
						});

						if (selectedClient) {
							this.props.handleSelectClientAction(selectedClient, 'frontoffice');
								if (this.props.match.path.split('/').some(item => item =='password')){	
									this.setState({
										recovery : true,
										recovery_token: typeof this.props.match.params.token === 'undefined' ? "" : this.props.match.params.token,  
									});
								} else {
									this.setState({
										recovery : false,
										recovery_token: null,  
									});
								}
						} else {
							this.props.replace('/login' + this.props.location.search);
						}
					} else if (typeof this.props.client === 'undefined' || this.props.client === null || this.props.client.id === 0) {
					  this.props.handleSelectClientAction(null, 'frontoffice')
					}
				}
			}
		}
	}

	render() {
		return this.props.platform == 'frontoffice' ? (
			<Grid container component="main" className="login-main">
				<MessageToastLogin />
				<Grid item xs={12} lg={7} className="login-content-left-image">
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								style={{ width: '70%' }}
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5} component={Paper} elevation={6} square>
					<LoginPanel recovery={this.state.recovery} recovery_token={this.state.recovery_token} />
				</Grid>
			</Grid>
		) : (
			<Grid container component="main" justify="center" className="login-main login-content-left-image">
				<MessageToastLogin />
				<Grid item xs={12} lg={12}>
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								style={{ width: '40%' }}
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={6} lg={6}>
					<LoginPanel />
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	languages: getLanguages(state.localize),
	settings: state.settings,
	config: state.config,
	client: state.login.client,
	login: state.login.login,
});

export default connect(mapStateToProps, { fetchTranslationsAction, handleSelectClientAction, replace })(Login);
