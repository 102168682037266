import {
	CHANGE_TABLE_PAGE,
	SET_TABLE_COUNT,
	HANDLE_TABLE_LOADING,
	SET_TABLE_FILTERS,
	SET_TABLE_CORE_STATE,
	SET_TABLE_ROWS_PER_PAGE,
	SET_TABLE_FILTER_LIST,
	SET_TABLE_SEARCH,
} from '../actions/types';
import { FETCH_SETTINGS } from '../../../actions/types';
import { CLEAR_REDUCER } from '../../Login/actions/types';
import TABLES from '../../../helpers/tables';

const initialState = Object.values(TABLES).reduce((result, item, index, array) => {
	result[item] = {
		page: 0,
		count: 0,
		loading: false,
		filters: {},
		search: null,
		filter_list: [],
		core: null,
		rows_per_page: 10,
	};
	return result;
}, {});

export default function (state = initialState, action) {
	switch (action.type) {
		case CHANGE_TABLE_PAGE:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], page: action.payload.page },
			};
		case SET_TABLE_COUNT:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], count: action.payload.count },
			};
		case SET_TABLE_FILTERS:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], filters: action.payload.filters },
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], search: action.payload.search },
			};
		case SET_TABLE_FILTER_LIST:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], filter_list: action.payload.filter_list },
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		case HANDLE_TABLE_LOADING:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], loading: action.payload.loading },
			};
		case SET_TABLE_CORE_STATE:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], core: action.payload.core },
			};
		case SET_TABLE_ROWS_PER_PAGE:
			return {
				...state,
				[action.payload.table_id]: { ...state[action.payload.table_id], rows_per_page: action.payload.rows_per_page },
			};
		case FETCH_SETTINGS:
      let auxState = { ...state };
			
      if (action.payload?.site?.tables) {
				Object.keys(state).forEach(table_id => {
          auxState[table_id].rows_per_page = action.payload.site.tables[table_id]?.rows_per_page?.length > 0 ? action.payload.site.tables[table_id]?.rows_per_page[0] : 10
        })
			}

			return {
				...auxState,
			};
		default:
			return state;
	}
}
